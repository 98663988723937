export const LOGIN = "/";
export const HOME = "/";
export const PAIR_DEVICE = "/pair-device";

// Admin routes
export const ADMIN_HOME = "/admin";
export const ORDERS = ADMIN_HOME;
export const MENU = ADMIN_HOME + "/menu";
export const QR_CODES = ADMIN_HOME + "/qr-codes";
export const USERS = ADMIN_HOME + "/user"
export const SETTINGS = ADMIN_HOME + "/settings";
export const ORDER_HISTORY = ADMIN_HOME + "/order-history";
export const HELP = ADMIN_HOME + "/help";
export const BILLING = ADMIN_HOME + "/billing";

// Kitchen routes
export const KICTHEN_HOME = "/kitchen";
export const KICTHEN_COMPLETED_ORDERS = "/kitchen/completed-orders";