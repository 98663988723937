import React, {useContext} from "react";
import { Kitchen } from "../../Components";
import * as Context from "../../Contexts";
import * as WSConstants from "../../Constants/websocket";
import * as Constants from "../../Constants";


const CompletedOrders = () => {
    const {
        completed_orders
      } = useContext(Context.MerchantContext);

    return (
        <Kitchen.Order.Orders eventType={WSConstants.MESSAGE_TYPES.COMPLETED_ORDERS_LIST} 
            statuses={[Constants.ORDER_ITEM_STATUSES.READY]}  
            orders={completed_orders}/>
    )
}
export default CompletedOrders;