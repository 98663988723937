import React from "react";
import * as utils from "../../../Utils";
import { Order } from "../../../Components/Admin";
import { ORDER_STATUSES } from "../../../Constants";

const OrderSummaryCard = ({ QRLabel, createdDate, userName, itemCount, price, hasNewOrders }) => {

    return (
        <>
            <div className="h-auto p-4 flex flex-col text-sm">
                <div className="flex justify-between items-center">
                    <span className=" text-black mr-2 px-3 py-1 rounded-md border border-gray-200">
                        {QRLabel}
                    </span>
                    <span className="text-gray-500">
                        Placed on {utils.dateFormatter(createdDate)}
                    </span>
                </div>
                <div className="flex justify-between my-3">
                    {
                        hasNewOrders
                            ? <Order.OrderStatusBadge status={ORDER_STATUSES.PLACED} />
                            : <div />
                    }
                    <span className="text-gray-500">Order by {userName}</span>
                </div>
                <div className="border-t border-gray-100 flex justify-between pt-4">
                    <span>{itemCount} Items</span>
                    <span>&#8377; {price}</span>
                </div>
            </div>
        </>
    )
}

export default OrderSummaryCard;