import React, { useState } from "react";
import { BiTrash } from "react-icons/bi";
import { TbEdit } from "react-icons/tb";
import * as menuService from "../../../Services/menuService";
import * as Common from "../../Common";
import { toast } from "react-hot-toast";


const CustomizationOptionRow = ({ performRefresh, cancelHandler, id = "", name = "", additional_price = null, mode = "edit", typeId }) => {
    const [deleteOption, setDeleteOption] = useState(false);
    const [editOption, setEditOption] = useState(false);
    const [optionName, setOptionName] = useState(name);
    const [optionPrice, setOptionPrice] = useState(additional_price);

    const DELETE_ITEM_MSG = `Are you sure you want to delete '${name}'?`;
    const CUSTOMIZATION_NAME_ID = `customization-name-${id}`;
    const CUSTOMIZATION_PRICE_ID = `customization-price-${id}`

    const removeOptionHandler = (isConfirm) => {
        if (isConfirm) {
            menuService.deleteCustomizationOption(id).then((response) => {
                if (response.status === 204) {
                    performRefresh();
                    toast.success('Customization was deleted successfully');
                };
            });
        }
        setDeleteOption(false);
    }

    const cancelUpdate = () => {
        if (mode === 'edit') {
            setEditOption(false);
        } else {
            cancelHandler();
        }
    };

    const updateOptionHandler = async () => {
        let params = {
            "name": optionName,
            "additional_price": optionPrice
        };

        if (mode === "edit") {
            menuService.updateCustomizationOption(id, params).then((response) => {
                if (response.status === 200) {
                    performRefresh();
                    toast.success('Customization was edited successfully');
                }
            });
        } else {
            params.customization_type = typeId
            if (optionName && optionPrice) {
                menuService.addCustomizationOption(params).then((response) => {
                    if (response.status === 201) {
                        performRefresh();
                        toast.success('Customization option added successfully');
                    }
                });
            } else {
                toast.error('Enter valid customization');
            }

        }
        setEditOption(false);
    }

    return (
        <div className="flex justify-between items-center font-semibold py-1 text-sm gap-3" key={id}>
            <input
                type="text"
                required={true}
                value={optionName}
                onChange={(e) => setOptionName(e.target.value)}
                id={CUSTOMIZATION_NAME_ID}
                disabled={!editOption && mode === 'edit'}
                className="px-2 py-1 text-sm w-3/5 bg-white rounded outline-none border border-slate-300 disabled:border-none invalid:border-rose-500 invalid:focus:ring-0"
            />
            <div className="flex items-center justify-center w-1/5">
                {
                    (!editOption && mode !== 'add') && <span>+</span>
                }
                <input
                    min="1"
                    max="100"
                    type="number"
                    required={true}
                    value={optionPrice}
                    onChange={(e) => setOptionPrice(e.target.value)}
                    disabled={!editOption && mode === 'edit'}
                    id={CUSTOMIZATION_PRICE_ID}
                    className="p-1 text-center extra-price w-full text-sm bg-white rounded outline-none border border-slate-300 disabled:border-none disabled:w-8 invalid:border-rose-500 invalid:focus:ring-0"
                />
            </div>

            <div className="flex w-1/5 items-center space-x-2 text-gray-700">
                {
                    <>
                        {
                            !editOption && mode === "edit" ?
                                <>
                                    <BiTrash
                                        size={18}
                                        onClick={() => setDeleteOption(true)}
                                        className="cursor-pointer text-red-400"
                                    />
                                    <TbEdit
                                        size={18}
                                        onClick={() => setEditOption(true)}
                                        className="cursor-pointer text-gray-500"
                                    />
                                    <Common.ConfirmPopup
                                        show={deleteOption}
                                        title={"Delete type"}
                                        body={DELETE_ITEM_MSG}
                                        confirmHandler={removeOptionHandler}
                                    />
                                </>
                                :
                                <>
                                    <button
                                        type="button"
                                        onClick={() => cancelUpdate()}
                                        className="h-8 px-3 text-xs text-gray-600 bg-white rounded-md border border-gray-200 disabled:text-gray-300"
                                    >
                                        Cancel
                                    </button>

                                    <button
                                        type="button"
                                        onClick={() => updateOptionHandler()}
                                        className="h-8 px-3 text-xs text-gray-600 bg-white rounded-md border border-gray-200 disabled:text-gray-300"
                                    >
                                        Done
                                    </button>
                                </>
                        }
                    </>
                }
            </div>
        </div>
    )
}
export default CustomizationOptionRow;