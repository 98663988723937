import InvoiceRow from "./InvoiceRow";
import {useExternalScript} from "../../Hooks";

const InvoiceTable = ({ data, reloadHandler }) => {
  
  const externalScript = "https://checkout.razorpay.com/v1/checkout.js";
  const state = useExternalScript(externalScript);
  

  return (
    <>
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="py-3 px-6 w-16"> #Invoice </th>
              <th scope="col" className="py-3 px-6 w-24"> Start date </th>
              <th scope="col" className="py-3 px-6 w-24"> End date</th>
              <th scope="col" className="py-3 px-6 w-16"> Amount</th>
              <th scope="col" className="py-3 px-6 w-24"> Due Date </th>
              <th scope="col" className="py-3 px-6 w-16"> Status </th>
              <th scope="col" className="py-3 px-6 w-full"> Actions </th>
            </tr>
          </thead>
          <tbody>
            {
              data.map(invoice => {
                return (
                  <InvoiceRow
                    invoiceId={invoice.id}
                    invoiceNo={invoice.invoice_no}
                    start_date={invoice.start_date}
                    end_date={invoice.end_date}
                    total={invoice.final_amount}
                    due_date={invoice.due_date}
                    status={invoice.status}
                    key={invoice.id}
                    reloadHandler={reloadHandler}

                  />
                )
              })
            }
          </tbody>
        </table>
      </div>
    </>
  )
}

export default InvoiceTable;