import React from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { ORDER_ITEM_STATUSES } from "../../../Constants";
import * as orderService from "../../../Services/orderServices";
import toast from "react-hot-toast";

const OrderItemRow = ({ id, name, quantity, status, customizations }) => {
    const statusClass = status === ORDER_ITEM_STATUSES.READY ? 'text-gray-500' : '';
    const nameStyle = statusClass;
    const customizationStyle = statusClass || 'text-gray-500';
    const quantityStyle = statusClass || 'text-gray-600';

    const changeStatus = (status) => {
        const params = {
            status: status
        }
        toast.promise(
            orderService.updateOrderItem(id, params).then(response => {
                if (response.status !== 200) {
                    console.log(response)
                }
            }),
            {
                loading: 'Saving...',
                success: `${name} is marked ready!`,
                error: 'Something went wrong! Please try again.',
            }
        );
    }

    const orderItemStatusAction = () => {
        /**
         * Function to show action for order item according to status.
         * Some examples:
         * - Show mark Ready button is status is NEW
         * - Show completed checkbox if status is READY
         */
        switch (status) {
            case ORDER_ITEM_STATUSES.NEW:
                return (
                    <button
                        type="button"
                        onClick={() => changeStatus(ORDER_ITEM_STATUSES.READY)}
                        className="h-8 px-3 text-xs text-green-500 bg-green-200 rounded-md"
                    >
                        Ready
                    </button>
                )

            case ORDER_ITEM_STATUSES.READY:
                return <AiOutlineCheckCircle size={25} color="gray" />;

            default:
                return null
        }
    }

    return (
        <div className="flex items-center justify-between w-full text-sm px-2 py-2 border-b border-b-gray-200" key={id}>
            <div>
                <p className={`text-md ${nameStyle}`}>{quantity} x {name}</p>
                <p className={`text-xs pt-1 ${customizationStyle}`}>{customizations}</p>
            </div>
            <div className="flex items-center justify-center gap-6">
                
                {orderItemStatusAction()}
            </div>
        </div>
    );
}

export default OrderItemRow;