import React, { useState, useEffect, useContext } from "react";
import * as qrService from "../../Services/qrServices";
import * as Context from "../../Contexts";
import * as Common from "../../Components/Common";
import { QR } from "../../Components/Admin";
import { MdOutlineQrCodeScanner } from "react-icons/md";

const QRCodes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [qrCodes, setQRCodes] = useState([]);
  const [showAddQRPopUp, setAddQRPopup] = useState(false);
  const [newQRLabel, setNewQRLabel] = useState("");
  const [shouldReload, setReload] = useState(true);

  const {
    selectedMerchant
  } = useContext(Context.MerchantContext);

  const addQRLabel = () => {
    qrService.createQR(selectedMerchant, newQRLabel).then(response => {
      if (response.status === 201) {
        setAddQRPopup(false);
        setNewQRLabel("");
        reload();
      }
    });
  };

  const newLabelChangeWatch = (e) => {
    let value = e.target.value;
    if (value.length >= 3) {
      setNewQRLabel(value);
    }
  };

  const reload = () => {
    setReload(!shouldReload);
  };

  const Loader = () => {
    return (
      <div className="flex flex-col justify-center items-center h-[calc(100vh-72px)]">
        <Common.Spinner size={12} />
        <p className="text-base py-3">Loading please wait...</p>
      </div>
    )
  };

  useEffect(() => {
    if (selectedMerchant) {
      setIsLoading(true);
      qrService.getQRCodes(selectedMerchant).then((response) => {
        setQRCodes(response.data.results);
        setIsLoading(false);
      })
    }
  }, [selectedMerchant, shouldReload]);


  return (
    <div className="flex flex-col">
      <Common.SubHeader pageTitle={"QR Setup"}>
        <button
          type="button"
          onClick={() => setAddQRPopup(true)}
          className="h-8 px-3 text-xs text-gray-600 bg-white rounded-md border border-gray-200 disabled:text-gray-300"
        >
          Add QR
        </button>
      </Common.SubHeader>
      
      <div className="p-8 h-[calc(100vh-140px)] overflow-y-scroll">
        {
          isLoading 
            ? <Loader />
            : ( qrCodes.length > 0 
              ? <QR.QRTable data={qrCodes} reloadHandler={reload} />
              : <Common.NoElements text="No QR"> 
                  <MdOutlineQrCodeScanner size={100} />
                </Common.NoElements>)
        }
      </div>

      <Common.Modal
        show={showAddQRPopUp}
        cancelHandler={() => setAddQRPopup(false)}
        confirmHandler={() => addQRLabel()}
        title={'Add QR'}
      >
        <div className="flex justify-between">
          <input
            type="text"
            required={true}
            defaultValue={newQRLabel}
            onKeyUp={newLabelChangeWatch}
            placeholder="Enter Label Name"
            className="appearance-none rounded text-gray-700 focus:outline-none focus:shadow-outline p-3 text-sm w-80 bg-white outline-none border border-slate-300 disabled:border-none invalid:border-rose-500 invalid:focus:ring-0"
          />
        </div>
      </Common.Modal>
    </div>
  );
};

export default QRCodes;
