import axios from "axios";
import client from "./axiosConfig";
import * as merchantService from "./merchantService";


export const updateItemQuantityToOrder = (orderItemId, quantity) => {
    const config = {
        url: `/orders/items/${orderItemId}/`,
        method: "PATCH",
        data: {
            'quantity': quantity,
        }
    };
    const response = client(config);
    return response;
}

export const removeItemFromOrder = (orderItemId) => {
    const config = {
        url: `/orders/items/${orderItemId}/`,
        method: "DELETE",
    };
    const response = client(config);
    return response;
}

export const updateOrderStatus = async (orderId, orderStatus) => {
    let merchantId = merchantService.getSelectedMerchant();
    const config = {
        url: `/merchant/${merchantId}/orders/${orderId}/`,
        method: "PATCH",
        data: {
            "status": orderStatus,
            "merchant": merchantId
        }
    };
    const response = client(config);
    return response;
}

export const addItemToOrder = (param = {}) => {
    const config = {
        url: `/orders/items/`,
        method: "POST",
        data: param
    };
    const response = client(config);
    return response;
};

export const addCustomizations = async (order_item_id, customizations = []) => {
    const config = {
        url: `/orders/customizations/`,
        method: "POST",
        data: {
            "order_item": order_item_id,
            "customizations": customizations
        }
    };
    const response = client(config);
    return response;
}

export const bulkOrderStatusUpdate = async (orderIds, orderStatus) => {
    try {
        const response = await axios.all(
            orderIds.map(orderId => {
                const merchantId = merchantService.getSelectedMerchant();
                const url = `/merchant/${merchantId}/orders/${orderId}/`;
                const data = {
                    "status": orderStatus,
                    "merchant": merchantId
                }
                return client.patch(url, data);
            })
        )
        return response;
    } catch (errResponse) {
        return errResponse;
    }
}

export const updateOrderItem = (id, params) => {
    const config = {
      url: `/orders/items/${id}/`,
      method: "PATCH",
      data: {
        ...params
      }
    }
    const response = client(config);
    return response;
  }