import React from "react";
import OrderItemRow from "./OrderItemRow";
import { Common } from "../../../Components";

const OrderCard = ({ orderId, id, lable, items, createdTime, orderNote, showTime }) => {

  return (
    <div className="w-full p-2 md:p-4" key={id}>
      <div className="flex flex-col w-full bg-white border-2 border-gray-100">
        <div className="flex items-center justify-between text-sm text-gray-600 font-semibold p-3 bg-gray-200/80">
          <div className="flex flex-col text-sm">
            <div>Order No <span>{orderId}</span></div>
            <div>Table : {lable}</div>
          </div>
          {
            showTime && 
            <div className="text-sm text-gray-700">
              <Common.TimeElapsed datetime={createdTime} />
            </div>
          }
        </div>

        <div className="flex flex-col">
          {
            items.map(item => {
              return (
                <div className="w-full px-2" key={item.id}>
                  <OrderItemRow
                    id={item.id}
                    key={item.id}
                    name={item.menu_item.name}
                    quantity={item.quantity}
                    status={item.status}
                    customizations={item.customizations}
                  />
                </div>
              )
            })
          }
        </div>
        {
          orderNote &&
          <div className="flex bg-secondary-100 p-3 text-xs text-gray-700 mb-2">
            {orderNote}
          </div>
        }
      </div>
    </div>
  )
}

export default OrderCard;