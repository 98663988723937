import React, { useContext, useEffect, useState } from "react";
import * as MenuService from "../../Services/menuService";
import * as Common from "../../Components/Common";
import * as Menus from "../../Components/Admin/Menu";
import * as Context from "../../Contexts";
import * as _ from "lodash";

const Menu = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [mode, setMode] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [showAddCategoryPopUp, setAddCategoryPopUp] = useState(false);
  const [categoryName, setCategoryName] = useState("");

  const {
    selectedMerchant,
    menuItems,
    setMenuItems,
    refresh,
    setRefresh
  } = useContext(Context.MerchantContext);

  const generateMenuByCategory = (menuItems) => {
    // Group menu Items by category
    let categoryMenu = {}

    menuItems.map((item) => {
      const category = item.category;
      const categoryExists = _.has(categoryMenu, category.name);
      if (categoryExists) {
        categoryMenu[category.name].items.push(item);
      } else {
        categoryMenu[category.name] = item.category;
        categoryMenu[category.name]['items'] = [item];
      }
    });

    return _.values(categoryMenu);
  };

  const newCategoryChangeWatch = (e) => {
    let value = e.target.value;
    if (value.length >= 3) {
      setCategoryName(value);
    }
  };

  const addMenuItem = () => {
    setMode("add");
    setSelectedMenuItem(null);
  };

  const editMenuItem = (menuItemId) => {
    setMode("edit");
    setSelectedMenuItem(menuItemId);
  };

  const addCategory = () => {
    MenuService.addMenuCategory(selectedMerchant, categoryName).then((response) => {
      if (response.status === 201) {
        setCategoryName("");
        setAddCategoryPopUp(false);
        setRefresh(!refresh);
      }
    });
  };

  const onMenuItemDelete = (menuItemId) => {
    if(menuItemId === selectedMenuItem) {
      setMode(null);
    }
  };

  useEffect(() => {
    if (selectedMerchant) {
      MenuService.getMenuItems(selectedMerchant).then((response) => {
        if (response.status === 200) {
          let _menuItems = response.data.results;
          _menuItems = generateMenuByCategory(_menuItems);
          setMenuItems(_menuItems);
          setIsLoading(false);
        }
      });
    }
  }, [selectedMerchant, refresh]);

  return (
    <div>
      <Common.SubHeader pageTitle={"Menu"}>
        <div className="flex justify-between gap-2">
          <button
            type="button"
            onClick={() => setAddCategoryPopUp(true)}
            className="h-8 px-3 text-xs text-gray-600 bg-white rounded-md border border-gray-200 disabled:text-gray-300"
          >
            + Add Category
          </button>
          <button
            type="button"
            onClick={() => addMenuItem()}
            className="h-8 px-3 text-xs text-gray-600 bg-white rounded-md border border-gray-200 disabled:text-gray-300"
          >
            + Add Item
          </button>
        </div>
      </Common.SubHeader>
      <div className="flex flex-col">
        <div className="flex flex-row h-[calc(100vh-136px)]">
          <div className="w-[42%] overflow-y-scroll border-r border-gray-200 bg-white">
            {
              isLoading
                ? (
                  <div className="flex flex-col w-full h-full items-center justify-center">
                    <Common.Spinner />
                    <p className=" p-2 text-gray-500">Loading Items...!</p>
                  </div>
                ) : <Menus.Accordion
                  menu={menuItems}
                  selectedMenuItem={selectedMenuItem}
                  onMenuItemSelected={(menuItemId) => editMenuItem(menuItemId)}
                  onMenuItemDelete={onMenuItemDelete}
                />
            }
          </div>
          <div className="w-[58%] overflow-y-scroll">
            {
              mode === "add" &&
              <Menus.AddMenuItem onCancel={() => setMode(null)} />
            }
            {
              mode === "edit" &&
              <Menus.EditMenuItem
                onCancel={() => setMode(null)}
                selectedMenuItem={selectedMenuItem} />
            }
            {
              mode === null &&
              <Menus.NoItemSelected />
            }
          </div>
        </div>
      </div>
      <Common.Modal
        show={showAddCategoryPopUp}
        cancelHandler={() => setAddCategoryPopUp(false)}
        confirmHandler={() => addCategory()}
        title={'Add QR'}
      >
        <div className="flex justify-between">
          <input
            type="text"
            required={true}
            defaultValue={categoryName}
            onKeyUp={newCategoryChangeWatch}
            placeholder="Enter Category Name"
            className="appearance-none rounded text-gray-700 focus:outline-none focus:shadow-outline p-3 text-sm w-80 bg-white outline-none border border-slate-300 disabled:border-none invalid:border-rose-500 invalid:focus:ring-0"
          />
        </div>
      </Common.Modal>
    </div>
  );
};

export default Menu;
