import React, { useState, useEffect, useContext } from "react";
import { Menu } from "..";
import * as Context from "../../../Contexts";
import * as menuService from "../../../Services/menuService";
import * as _ from "lodash";

const TypeOptionsContainer = ({ itemId, typeId }) => {
    const [options, setOptions] = useState([])
    const [addOption, setAddOption] = useState(false);
    const { selectedMerchant } = useContext(Context.MerchantContext);
    const [refreshOptions, setRefreshOption] = useState(false);

    const cancelChanges = () => {
        setAddOption(false);
    }

    useEffect(() => {
        menuService.getSpecificCustomizationType(selectedMerchant, itemId, typeId).then((response) => {
            if (response.status === 200) {
                const _options = _.sortBy(response.data.options, 'name');
                setOptions(_options);
                setAddOption(false);
            }
        });

    }, [refreshOptions])


    return (
        <div>
            <div>
                <div className="flex w-full gap-3 p-2 bg-slate-100 text-black rounded-sm my-1">
                    <div className="text-xs w-3/5">Option</div>
                    <div className="text-xs w-1/5">Extra Cost</div>
                    <div className="text-xs w-1/5">Actions</div>
                </div>
                {
                    options.length > 0 &&
                    <>
                        {options.map(option => {
                            return (
                                <Menu.CustomizationOptionRow
                                    performRefresh={() => setRefreshOption(!refreshOptions)}
                                    id={option.id}
                                    name={option.name}
                                    additional_price={option.additional_price}
                                    mode='edit'
                                    key={option.id}
                                />
                            );
                        })}
                    </>
                }
                {
                    options.length == 0 && !addOption &&
                    <div className="flex items-center justify-center h-28">
                        <p className="text-sm my-4">No options added yet!</p>
                    </div>
                }
                {addOption
                    && <Menu.CustomizationOptionRow
                        performRefresh={() => setRefreshOption(!refreshOptions)}
                        cancelHandler={() => cancelChanges()}
                        mode='add'
                        typeId={typeId}
                    />}
            </div>
            <div>
                <button
                    type="button"
                    onClick={() => setAddOption(true)}
                    className="w-full p-2.5 text-xs text-slate-600 bg-white border border-gray-300 rounded-md"
                >
                    + Add Option
                </button>
            </div>
        </div>
    );
}

export default TypeOptionsContainer;

