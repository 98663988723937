import React, { useEffect, useState, useContext } from "react";
import { Common } from "../../../Components";
import * as menuService from "../../../Services/menuService";
import * as Context from "../../../Contexts";

const ItemCustomizations = ({ itemId, onCustomizationUpdate }) => {
    const [customizations, setCustomizations] = useState({});
    const { selectedMerchant } = useContext(Context.MerchantContext);

    const validateCustomization = () => {
        /**
         * @description Check if required customization options are selected
         */

        let customizationIds = Object.keys(customizations);

        for (let i = 0; i < customizationIds.length; i++) {
            let customization = customizations[customizationIds[i]];
            let options = customization.options;
            let optionIds = Object.keys(options);
            let selectedCount = 0;

            for (let j = 0; j < optionIds.length; j++) {
                let option = options[optionIds[j]];
                if (option.isSelected) {
                    selectedCount += 1;
                }
            };

            let isValid = customization.min_options <= selectedCount && selectedCount <= customization.max_options;
            if (!isValid) return false;
        }
        return true;
    }

    const changeRadioOption = (option) => {
        /**
         * @description Update radio buttons according to selected option
         * @param {Object} Customization option object
         */

        let tempCustomizations = { ...customizations };
        let customizationType = tempCustomizations[option.customization_type];
        let changedOption = customizationType.options[option.id];

        Object.keys(customizationType.options).forEach((optionId) => {
            if (option.id !== optionId) {
                customizationType.options[optionId].isSelected = false;
            }
        })
        changedOption.isSelected = true;
        setCustomizations(tempCustomizations);
    }

    const toggleCheckboxOption = (option) => {
        /**
         * @description Toggle option checkbox
         * @param {Object} option - Customization option object
         */
        let tempCustomizations = { ...customizations };
        let customizationType = tempCustomizations[option.customization_type];
        let changedOption = customizationType.options[option.id];
        changedOption.isSelected = !changedOption.isSelected;
        setCustomizations(tempCustomizations);
    }
    const customizationOptions = (options, minOptions, maxOptions) => {
        /**
         * @description Construct customization option's JSX
         * @param  {Object} options    - Options of customization type
         * @param  {Number} minOptions - Min options to be selected
         * @param  {Number} minOptions - Max options to be selected
         * @returns JSX element
         * */

        return Object.keys(options).map((optionId) => {
            let option = options[optionId];
            let isRadioButton = minOptions === 1 && maxOptions === 1
            let roundedStyle = isRadioButton ? 'rounded-full' : 'rounded-sm';

            return (
                <div key={option.id} className="flex justify-between p-2 text-sm text-gray-500">
                    <p>{option.name}</p>
                    <div className="flex ">
                        {option.additional_price ? <p className="px-2">+ {option.additional_price}</p> : null}
                        <input
                            className={`h-5 w-5 bg-white border border-gray-300 ${roundedStyle} checked:bg-blue-600 cursor-pointer`}
                            type={"checkbox"}
                            name={option.customization_type}
                            value={option.id}
                            onChange={
                                () => isRadioButton ? changeRadioOption(option) : toggleCheckboxOption(option)
                            }
                            checked={option.isSelected}
                        />
                    </div>
                </div>
            )
        })
    }

    const getCustomizations = async (itemId) => {
        /**
         * @descrption Get customizations from backend and transform
         * @param {String} itemId - UUID of Item Id
         */

        let response = await menuService.getMenuItemCustomizations(selectedMerchant, itemId);
        let customizationTypes = response.data.results;
        let customizations = {};

        customizationTypes.forEach((customizationType) => {
            let options = [...customizationType.options];
            customizations[customizationType.id] = customizationType;
            customizations[customizationType.id].options = {};

            options.forEach((option) => {
                customizations[customizationType.id].options[option.id] = option;
                customizations[customizationType.id].options[option.id].isSelected = false;
            });
        });

        return customizations;
    }


    const getSelectedCustomizations = () => {
        let selectedCustomizations = [];
        Object.keys(customizations).forEach((customizationTypeId) => {
            let customizationType = customizations[customizationTypeId];
            Object.keys(customizationType.options).forEach((optionId) => {
                let isSelected = customizationType.options[optionId].isSelected;
                if (isSelected) {
                    let selectedCustomization = {
                        "customization_option": optionId
                    }
                    selectedCustomizations.push(selectedCustomization);
                }
            })
        });
        return selectedCustomizations;
    }

    useEffect(() => {
        getCustomizations(itemId).then((response) => {
            setCustomizations(response);
        });
    }, [itemId]);

    useEffect(() => {
        const isValid = validateCustomization();
        const currentState = {
            customizations: getSelectedCustomizations(customizations),
            isValid: isValid,
        }
        onCustomizationUpdate(currentState);
    }, [customizations])

    return (
        <>
            {
                customizations && Object.keys(customizations).length > 0
                    ? <ul className="space-y-3">
                        {
                            Object.keys(customizations).map((customizationId) => {
                                let customization = customizations[customizationId];
                                return (
                                    <li key={customization.id}>
                                        <div href="#" className="flex items-center p-3 text-sm text-gray-900 bg-gray-50">
                                            <span className="flex-1 whitespace-nowrap">{customization.name}</span>
                                            <span
                                                className="inline-flex items-center justify-center px-2 py-0.5 ml-3 text-xs font-medium text-gray-500 bg-gray-200 rounded">
                                                Minimum {customization.min_options}
                                            </span>
                                        </div>
                                        <div>
                                            {
                                                customizationOptions(
                                                    customization.options,
                                                    customization.min_options,
                                                    customization.max_options,
                                                )
                                            }
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    : <div className="w-full flex justify-center py-6">
                        <Common.Spinner size={10} />
                    </div>

            }
        </>
    )
}

export default ItemCustomizations;