import React, { useState, useContext } from "react";
import InvoiceModal from "./InvoiceModal";
import * as Context from "../../../Contexts";
import * as utils from "../../../Utils";
import * as billingService from "../../../Services/billingServices";
import { FiEye } from "react-icons/fi";
import { MdPayment } from "react-icons/md";
import { Common } from "../../../Components";
import { getUser } from "../../../Services/utils";

const InvoiceRow = ({ invoiceId, invoiceNo, start_date, end_date, total, due_date, status, reloadHandler }) => {

    const [invoiceDetails, setInvoiceDetails] = useState(null);
    const [displayInvoice, setDisplayInvoice] = useState(false);

    const dateOptions = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    };

    const {
        selectedMerchant
    } = useContext(Context.MerchantContext);

    const getInvoiceDetails = () => {
        billingService.getInvoiceById(invoiceId, selectedMerchant).then((response) => {
            if (response.status === 200) {
                setInvoiceDetails(response.data);
                setDisplayInvoice(true);
            }
        })
    }

    const makePayment = () => {
        var user = getUser();
        billingService.createPaymentOrder(invoiceId).then((response) => {
            if (response.status === 200) {
                const options = {
                    key: process.env.REACT_APP_RAZORPAY_KEY_ID, 
                    amount: response.data.amount.toString(),
                    currency: "INR",
                    name: "Annapurna",
                    order_id: response.data.pg_order_id,
                    handler: async function (response) {
                        reloadHandler();
                    },
                    prefill: {
                        name: user.name,
                        email: user.email
                    }
                };
        
                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
            }
        });

    }

    const invoiceActions = () => {
        return (
            <>
                <button
                    type="button"
                    onClick={() => getInvoiceDetails()}
                    className="h-8 px-3 text-xs text-gray-600 bg-white rounded-md border border-gray-200 disabled:text-gray-300"
                >
                    <div className="flex items-center">
                        <FiEye size={15} /> &nbsp; View
                    </div>
                </button>
                {
                    status === "PENDING" && total > 0 ?
                        <button
                            type="button"
                            onClick={() => makePayment()}
                            className="h-8 px-3 text-xs text-gray-600 bg-white rounded-md border border-gray-200 disabled:text-gray-300"
                        >
                            <div className="flex items-center">
                                <MdPayment size={15} /> &nbsp; Make a Payment
                            </div>
                        </button> : null
                }
            </>
        )
    }

    return (
        <>
            <tr className="bg-white border-b hover:bg-gray-50">
                <th scope="row" className="flex items-center p-4 w-16 font-medium text-gray-900 whitespace-nowrap">
                    <div className="text-base font-semibold">
                        <p className="px-2 py-1 text-sm">{invoiceNo}</p>
                    </div>
                </th>
                <td className="py-4 px-6 w-20">
                    <div className="flex items-center w-24">
                        {utils.dateFormatter(start_date, dateOptions)}
                    </div>
                </td>
                <td className="py-4 px-6 w-20">
                    <div className="flex items-center w-24">
                        {utils.dateFormatter(end_date, dateOptions)}
                    </div>
                </td>
                <td className="py-4 px-6 w-20">
                    <div className="flex items-center w-20">
                        <span>&#8377; {total}</span>
                    </div>
                </td>
                <td className="py-4 px-6 w-20">
                    <div className="flex items-center w-24">
                        {utils.dateFormatter(due_date, dateOptions)}
                    </div>
                </td>
                <td className="py-4 px-6 w-20">
                    <div className="flex items-center w-20">
                        {status}
                    </div>
                </td>
                <td className="py-4 px-6 flex space-x-3">
                    {invoiceActions()}
                </td>
            </tr>

            <Common.Modal
                show={displayInvoice}
                cancelHandler={() => setDisplayInvoice(false)}
                title={'Invoice Details'}
            >
                <InvoiceModal data={invoiceDetails} />
            </Common.Modal>
        </>
    )
}

export default InvoiceRow;