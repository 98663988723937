import React, { useState, useContext } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { TbEdit } from "react-icons/tb";
import toast from 'react-hot-toast';
import * as Common from "../../Common";
import * as menuService from "../../../Services/menuService";
import * as Context from "../../../Contexts";


const CatrgoryAccordionItem = ({ id, title, is_active, children }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [categoryName, setCategoryName] = useState(title);
    const [editCategory, setEditCategory] = useState(false);
    const [deleteCategory, setDeleteCategory] = useState(false);
    const [isActive, setIsActive] = useState(is_active);
    const { selectedMerchant, refresh, setRefresh } = useContext(Context.MerchantContext);

    const deleteCategoryHandler = (confirm) => {
        if (confirm) {
            toast.promise(
                menuService.deleteMenuCategory(selectedMerchant, id).then((response) => {
                    if (response.status !== 204) {
                        throw "error";
                    }
                    setRefresh(!refresh);
                }),
                {
                    loading: 'Deleting categroy...',
                    success: 'Category deleted successfully!',
                    error: 'Something went wrong! Please try again.',
                }
            )
        }
        setDeleteCategory(false);
    }

    const editCategoryHandler = () => {
        const params = {
            name: categoryName,
        }
        if(!categoryName){
            toast.error("Enter valid category name");
            return;
        }
        toast.promise(
            menuService.updateMenuCategory(selectedMerchant, id, params).then((response) => {
                if (response.status !== 200) {
                    throw "error";
                }
                setEditCategory(false);
                setRefresh(!refresh);
            }),
            {
                loading: 'Saving...',
                success: 'Category updated successfully!',
                error: 'Something went wrong! Please try again.',
            }
        )
    }

    const enableDisableCategoryHandler = () => {
        const params = {
            is_active: !isActive,
        }
        toast.promise(
            menuService.updateMenuCategory(selectedMerchant, id, params).then((response) => {
                if (response.status === 200) {
                    setIsActive(response.data.is_active);
                    setRefresh(!refresh);
                } else {
                    throw "error";
                }
            }),
            {
                loading: 'Updating category...',
                success: 'Category updated successfully!',
                error: 'Something went wrong! Please try again.',
            }
        )
    }


    return (
        <div key={id}>
            <div className="flex items-center justify-between w-full p-3 border-b border-gray-100">
                <div
                    onClick={() => setIsOpen(!isOpen)}
                    className="flex items-center justify-between w-full  text-sm font-semibold text-left text-gray-900 cursor-pointer"
                >
                    <div className="flex items-center justify-left">
                        <span className="pr-2">
                            {isOpen ? <AiOutlineMinus /> : <AiOutlinePlus />}
                        </span>
                        <div>
                            <input
                                type="text"
                                required={true}
                                value={categoryName}
                                onChange={(e) => setCategoryName(e.target.value)}
                                id={id}
                                disabled={!editCategory}
                                className="px-2 py-1  text-sm font-semibold w-full bg-white rounded outline-none border border-slate-300 disabled:border-none invalid:border-rose-500 invalid:focus:ring-0"
                            />
                        </div>
                    </div>
                </div>
                <div className="flex justify-between gap-2">
                    {
                        !editCategory
                            ? <>
                                <Common.Toggle
                                    onToggleChange={enableDisableCategoryHandler}
                                    toggleName={''}
                                    toggleStatus={is_active}
                                ></Common.Toggle>

                                <BiTrash
                                    size={20}
                                    onClick={() => setDeleteCategory(true)}
                                    className="cursor-pointer text-red-400"
                                />
                                <TbEdit
                                    size={20}
                                    onClick={() => setEditCategory(true)}
                                    className="cursor-pointer text-gray-500"
                                />
                            </>
                            : <button
                                type="button"
                                onClick={editCategoryHandler}
                                className="h-6 px-2 text-xs text-gray-600 bg-white rounded-md border border-gray-200 disabled:text-gray-300"
                            >
                                Done
                            </button>
                    }
                    <Common.ConfirmPopup
                        show={deleteCategory}
                        title={"Delete type"}
                        body={`Are you sure you want to delete '${categoryName}'?`}
                        confirmHandler={deleteCategoryHandler}
                    />
                </div>
            </div>
            <div>
                <div className="font-light">
                    {isOpen && children}
                </div>
            </div>

        </div>
    )
}

export default CatrgoryAccordionItem;