import React from "react";
import { Route, Routes } from "react-router-dom";
import { BrowserTracing } from "@sentry/tracing";
import { Login, Pairing, NotFound, Admin, Kitchen } from "./Pages";
import { Layouts } from "./Components";
import * as routes from "./Constants/routes";
import * as Sentry from "@sentry/react";
import * as Utils from "./Services/utils";
import * as Constants from "./Constants";

try {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_URL,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
    });
} catch (error) {
    console.log("Ignore: Sentry init issue!")
}

function App() {
    let user = null

    try {
        user = Utils.getUser()
    } catch (error) {
    }

    return (
        <Routes>
            {/* Public Routes */}
            <Route path={routes.HOME} element={<Layouts.PublicLayout />}>
                <Route path={routes.LOGIN} element={<Login />} />
                <Route path={routes.PAIR_DEVICE} element={<Pairing />} />
            </Route>
            {
                user && user.role === Constants.USER_ROLE_ADMIN &&
                <Route path={routes.ADMIN_HOME} element={<Layouts.AdminLayout />} >
                    <Route path={routes.ORDERS} element={<Admin.Orders />} />
                    <Route path={routes.ORDER_HISTORY} element={<Admin.OrderHistory />} />
                    <Route path={routes.MENU} element={<Admin.Menu />} />
                    <Route path={routes.QR_CODES} element={<Admin.QRCodes />} />
                    <Route path={routes.USERS} element={<Admin.Users />} />
                    <Route path={routes.BILLING} element={<Admin.Billing />} />
                    <Route path={routes.HELP} element={<Admin.Help />} />
                    <Route path='*' element={<NotFound />} />
                </Route>
            }
            {
                user && user.role === Constants.USER_ROLE_KITCHEN &&
                <Route path={routes.KICTHEN_HOME} element={<Layouts.KitchenLayout />}>
                    <Route
                        path={routes.KICTHEN_HOME}
                        element={
                            <Kitchen.Orders />
                        }
                    />
                    <Route
                        path={routes.KICTHEN_COMPLETED_ORDERS}
                        element={
                            <Kitchen.CompletedOrders />
                        }
                    />
                    <Route path='*' element={<NotFound />} />
                </Route>

            }
            <Route path='*' element={<NotFound />} />
        </Routes>
    );
}

export default App;
