import * as utils from "../../../Utils";
const InvoiceModal = ({ data }) => {

  const dateOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  };

  return (
    <>
      <div>
        <div className="flex flex-col bg-white p-4 my-1 text-sm text-gray-700">
          <div className="mb-2">
            <div className="px-2 py-1 text-sm">
              <span className="text-sm font-semibold">Invoice No : </span>
              <span>{data.invoice_no}</span>
            </div>

            <div className="flex flex-row">
              <div className="w-1/2">
                <div className="px-2 py-1 text-sm">
                  <span className="text-sm font-semibold">Start Period :</span>
                  <span>{utils.dateFormatter(data.start_date, dateOptions)}</span>
                </div>
                <div className="px-2 py-1 text-sm">
                  <span className="text-sm font-semibold">End Period  : </span>
                  <span>{utils.dateFormatter(data.end_date, dateOptions)}</span>
                </div>
              </div>

              <div className="w-1/2">
                <div className="px-2 py-1 text-sm">
                  <span className="text-sm font-semibold">Due Date  : </span>
                  <span>{utils.dateFormatter(data.due_date, dateOptions)}</span>
                </div>
                <div className="px-2 py-1 text-sm">
                  <span className="text-sm font-semibold"> Status : </span>
                  <span>{data.status}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="py-3 px-6 w-16"> Total Orders </th>
                  <th scope="col" className="py-3 px-6 w-20"> Unit Price </th>
                  <th scope="col" className="py-3 px-6 w-20"> Total Amount</th>
                  <th scope="col" className="py-3 px-6 w-16"> Discount</th>
                  <th scope="col" className="py-3 px-6 w-20"> Total Tax </th>
                  <th scope="col" className="py-3 px-6 w-16"> Total </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b hover:bg-gray-50">
                  <th scope="row" className="flex items-center p-4 w-16 font-medium text-gray-900 whitespace-nowrap">
                    <div className="text-base font-semibold">
                      <p className="px-2 py-1 text-sm">{data.total_orders}</p>
                    </div>
                  </th>
                  <td className="py-4 px-6 w-20">
                    <div className="flex items-center w-20">
                      {data.unit_price}
                    </div>
                  </td>
                  <td className="py-4 px-6 w-20">
                    <div className="flex items-center w-20">
                      <span>&#8377; {data.total_amount}</span>
                    </div>
                  </td>
                  <td className="py-4 px-6 w-20">
                    <div className="flex items-center w-20">
                      {data.discount_percentage}
                    </div>
                  </td>
                  <td className="py-4 px-6 w-20">
                    <div className="flex items-center w-20">
                      <span>&#8377; {data.total_tax}</span>
                    </div>
                  </td>
                  <td className="py-4 px-6 w-20">
                    <div className="flex items-center w-20">
                      <span>&#8377; {data.final_amount}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default InvoiceModal;