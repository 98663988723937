import React, { useContext, useState, useRef, useEffect } from "react";
import {
    AiOutlineDelete,
    AiOutlineEdit,
    AiOutlineMobile,
} from "react-icons/ai";
import { Common } from "../..";
import { USER_ROLES, USER_ROLE_KITCHEN } from "../../../Constants";
import * as Context from "../../../Contexts";
import * as userService from "../../../Services/userServices";
import * as _ from 'lodash';
import toast from "react-hot-toast";


const UserTableRow = ({ userId, merchantUserId, first_name, last_name, email, role, reloadHandler }) => {
    const [user, setUser] = useState({
        "first_name": first_name,
        "last_name": last_name
    })
    const [editUser, setEditUser] = useState(false);
    const [deleteUserPopup, setDeleteUserPopup] = useState(false);
    const [addDevicePopup, setAddDevicePopup] = useState(false);
    const [pairingCode, setDevicePairingCode] = useState([]);
    const digitRefs = useRef([]);
    
    const can_pair_device = [USER_ROLE_KITCHEN]

    const {
        selectedMerchant
    } = useContext(Context.MerchantContext);

    const updateUser = () => {
        if (user.first_name && user.last_name) {
            userService.updateMerchantUser(selectedMerchant, userId, user).then((response) => {
                if (response.status === 200) {
                    toast.success("User updated successfully");
                } else {
                    toast.error("Error in user update");
                };
                setEditUser(false);
                reloadHandler();
            })
        } else {
            toast.error("Enter valid details");
        }
    }

    const deleteUser = () => {
        userService.deleteMerchantUser(selectedMerchant, userId).then((response) => {
            if (response.status === 204) {
                toast.success("User deleted successfully");
            } else {
                toast.error("Error in deleting user");
            }
            setDeleteUserPopup(false);
            reloadHandler();
        })
    }

    const mapDevice = () => {
        const _pairingCode = pairingCode.join('')
        if (_pairingCode.length === 6) {
            let params = {
                "code": _pairingCode,
                "merchant_user": merchantUserId
            };
            userService.addDevice(params).then((response) => {
                if (response.status === 200) {
                    toast.success("Device paired successfully");
                } else {
                    toast.error("Pairing unsuccessful");
                }
                setAddDevicePopup(false);
            })
        } else {
            toast.error("Enter valid pairing code");
        }
    }

    const onFieldValueChange = (e) => {
        const fieldName = e.target.name;
        let fieldValue = e.target.value;
        setUser({ ...user, [fieldName]: fieldValue });
    };

    const pairingCodeOnChange = (e) => {
        const value = e.target.value;
        const index = Number(e.target.id);

        if (value && !isNaN(value) && e.keyCode !== 8) {
            let _pairingCode = [...pairingCode]
            _pairingCode[index] = value;
            setDevicePairingCode(_pairingCode);
            if (index < pairingCode.length - 1) {
                digitRefs.current[index + 1].focus();
            }
            return;
        }
        digitRefs.current[index] = '';
    }

    const onDigitDelete = (e) => {
        const index = Number(e.target.id);

        if (e.keyCode === 8) {
            let _pairingCode = [...pairingCode]
            _pairingCode[index] = '';
            if (index > 0) {
                digitRefs.current[index - 1].focus();
                digitRefs.current[index - 1] = '';
            }
            setDevicePairingCode(_pairingCode);
            return;
        }
    }

    useEffect(() => {
        setDevicePairingCode(Array(6).fill(''))
    }, [addDevicePopup])

    const userActions = () => {
        return (
            <>
                {
                    !editUser && <>
                        <button
                            type="button"
                            onClick={() => setEditUser(true)}
                            className="h-8 px-3 text-xs text-gray-600 bg-white rounded-md border border-gray-200 disabled:text-gray-300"
                        >
                            <div className="flex items-center">
                                <AiOutlineEdit className="mr-1" /> Edit
                            </div>
                        </button>
                        <button
                            type="button"
                            onClick={() => setDeleteUserPopup(true)}
                            className="h-8 px-3 text-xs text-gray-600 bg-white rounded-md border border-gray-200 disabled:text-gray-300"
                        >
                            <div className="flex items-center">
                                <AiOutlineDelete color="red" className="mr-1" />Delete
                            </div>
                        </button>
                        <Common.Modal
                            show={deleteUserPopup}
                            cancelHandler={() => setDeleteUserPopup(false)}
                            confirmHandler={deleteUser}
                            title={'Delete User'}
                        >
                            <p className="text-sm text-gray-700">
                                Are you sure? You want to delete '{user.first_name + " " + user.last_name}'.
                            </p>
                        </Common.Modal>

                        {
                            can_pair_device.includes(role) &&
                            <>
                                <button
                                    type="button"
                                    onClick={() => setAddDevicePopup(true)}
                                    className="h-8 px-3 text-xs text-gray-600 bg-white rounded-md border border-gray-200 disabled:text-gray-300"
                                >
                                    <div className="flex items-center">
                                        <AiOutlineMobile className="mr-1" />Device
                                    </div>
                                </button>
                                <Common.Modal
                                    show={addDevicePopup}
                                    cancelHandler={() => setAddDevicePopup(false)}
                                    confirmHandler={mapDevice}
                                    title={'Add a device'}

                                >
                                    <div className="flex flex-col w-full">
                                        <p className="text-sm mb-2 text-gray-600">Enter device pairing code</p>
                                        <div className="flex gap-2">
                                            {
                                                pairingCode.map((digit, idx) =>
                                                    <input
                                                        id={idx}
                                                        type="text"
                                                        maxLength={1}
                                                        minLength={1}
                                                        value={digit}
                                                        ref={el => digitRefs.current[idx] = el}
                                                        onChange={pairingCodeOnChange}
                                                        onKeyDown={onDigitDelete}
                                                        className="p-2 text-md w-12 h-14 text-gray-700 font-bold text-center rounded-md border-1 mr-0  invalid:border-rose-500 invalid:focus:ring-0"
                                                    />
                                                )
                                            }
                                        </div>
                                    </div>
                                </Common.Modal>
                            </>
                        }
                    </>
                }
                {
                    editUser &&
                    <>
                        <button
                            type="button"
                            onClick={updateUser}
                            className="h-8 px-3 text-xs text-gray-600 bg-white rounded-md border border-gray-200 disabled:text-gray-300"
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            onClick={() => setEditUser(false)}
                            className="h-8 px-3 text-xs text-gray-600 bg-white rounded-md border border-gray-200 disabled:text-gray-300"
                        >
                            Cancel
                        </button>
                    </>
                }
            </>
        )
    };

    return (
        <>
            <tr className="bg-white border-b hover:bg-gray-50">
                <td className="p-2 w-44">
                    <div className="text-base text-gray-900">
                        <input
                            type="text"
                            required={true}
                            name="first_name"
                            defaultValue={user.first_name}
                            onChange={onFieldValueChange}
                            disabled={!editUser}
                            className="px-2 py-1 text-sm w-24 bg-white rounded outline-none border border-slate-300 disabled:border-none invalid:border-rose-500 invalid:focus:ring-0"
                        />
                    </div>
                </td>
                <td className="p-2 w-44">
                    <div className="text-base text-gray-900">
                        <input
                            type="text"
                            required={true}
                            name="last_name"
                            defaultValue={user.last_name}
                            onChange={onFieldValueChange}
                            disabled={!editUser}
                            className="px-2 py-1 text-sm w-44 bg-white rounded outline-none border border-slate-300 disabled:border-none invalid:border-rose-500 invalid:focus:ring-0"
                        />
                    </div>
                </td>
                <td className="p-2 w-44">
                    <div className="text-base">
                        <p className="p-2 text-sm w-56 truncate ...">{email}</p>
                    </div>
                </td>
                <td className="p-2 w-44">
                    <div className="text-base">
                        <p className="p-2 text-sm w-44 truncate ...">
                            {_.find(USER_ROLES, { 'role': role }).title}
                        </p>
                    </div>
                </td>
                <td className="p-2 flex space-x-3">
                    {userActions()}
                </td>
            </tr>
        </>
    )
}

export default UserTableRow;