import React, { useState } from "react";
import { Order } from "../../../Components/Admin";
import { forwardRef, useImperativeHandle } from 'react';
import * as orderServices from "../../../Services/orderServices";

const SelectedMenuItem = ({ selectedItem, orderId, onOrder }, ref) => {
    const [itemQuantity, setItemQuantity] = useState(1);
    const [isQuantityValid, setIsQuantityValid] = useState(true);
    const [isCustomizationValid, setIsCustomizationValid] = useState(true);
    const [customizations, setCustomizations] = useState({});

    useImperativeHandle(ref, () => ({
        orderMenuItem
    }));

    const orderItemQuntityChangeWatch = (e) => {
        let value = Number(e.target.value);
        if (100 < value || value < 1) {
            setIsQuantityValid(false);
        } else {
            setItemQuantity(value)
            setIsQuantityValid(true);
        }
    }

    const onCustomizationUpdate = (updatedState) => {
        setCustomizations(updatedState.customizations)
        setIsCustomizationValid(updatedState.isValid);
    }

    const orderMenuItem = async () => {
        if (!isQuantityValid) return;
        if (selectedItem.has_customization) {
            if (!isCustomizationValid)
                return;
        }

        let params = {
            "menu_item": selectedItem.id,
            "quantity": itemQuantity,
            "order": orderId
        }
        const response = await orderServices.addItemToOrder(params);
        if (response) {
            if (selectedItem.has_customization) {
                await orderServices.addCustomizations(response.data.id, customizations);
                onOrder();
            } else {
                onOrder();
            }
        }
    }

    return (
        <div>
            <div className="flex justify-between items-top py-2 text-sm border-b border-gray-20">
                <div className="w-5/6">
                    <p className="font-semibold text-gray-700">{selectedItem.name}</p>
                    <p className="text-xs text-gray-500 pr-2">{selectedItem.description}</p>
                </div>
                <span className=""></span>
                <div className="w-1/6 flex flex-col items-center">
                    <input
                        min="1"
                        max="100"
                        type="number"
                        required={true}
                        defaultValue={itemQuantity}
                        onKeyUp={orderItemQuntityChangeWatch}
                        className="px-1 py-[5px] text-sm text-center w-full bg-white rounded outline-none border border-slate-300 disabled:border-none disabled:w-8 invalid:border-rose-500 invalid:focus:ring-0"
                    />
                    <span className="text-xs text-gray-400">Quantity</span>
                </div>
            </div>
            <div className="text-xs text-red-500 py-2">
                {!isQuantityValid && <span>Enter a valid quantity. </span>}
                {!isCustomizationValid && <span>Select the customizations</span>}
            </div>
            <>
                {
                    selectedItem.has_customization &&
                    <Order.ItemCustomizations
                        itemId={selectedItem.id}
                        onCustomizationUpdate={onCustomizationUpdate}
                    />
                }
            </>
        </div>
    )
}
export default forwardRef(SelectedMenuItem);