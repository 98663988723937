import React from "react";

const ViewQR = ({ qrCode, height, width }) => {
  return (
    <>
      {
        qrCode &&
        <img src={qrCode} alt="Qr code" height={height} width={width} />
      }
    </>
  )
}

export default ViewQR;