import React from "react";
import { BsTelephone } from "react-icons/bs";
import { GrHomeRounded } from "react-icons/gr";
import { AiOutlineMail } from "react-icons/ai";

const Help = () => {
  return (
    <section className="relative z-10 overflow-hidden bg-white p-20">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap lg:justify-between">
          <div className="w-full px-4 lg:w-1/2 xl:w-6/12">
            <div className="mb-12 max-w-[570px] lg:mb-0">
              <span className="mb-4 block text-base font-semibold">
                Contact Us
              </span>
              <h2
                className="text-dark mb-6 text-[32px] font-bold uppercase sm:text-[40px] lg:text-[36px] xl:text-[40px]"
              >
                GET IN TOUCH WITH US
              </h2>
              <p className="text-body-color mb-9 text-base leading-relaxed">
                If you have any issues with our products, please don't hesitate
                to get in touch with us. We are always ready to assist you and
                find a solution to your problem. Our customer service team is
                dedicated to resolving any issues you may have in a timely and
                efficient manner.
              </p>
              <div className="mb-8 flex w-full max-w-[370px]">
                <div
                  className="bg-black text-black mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-opacity-5 sm:h-[70px] sm:max-w-[70px]"
                >
                  <GrHomeRounded size={35} />
                </div>
                <div className="w-full">
                  <h4 className="text-dark mb-1 text-xl font-bold">Our Location</h4>
                  <p className="text-body-color text-base">
                    Samrat Garden, Hadapsar, Pune - 411028
                  </p>
                </div>
              </div>
              <div className="mb-8 flex w-full max-w-[370px]">
                <div
                  className="bg-black text-black mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-opacity-5 sm:h-[70px] sm:max-w-[70px]"
                >
                  <BsTelephone size={35} />
                </div>
                <div className="w-full">
                  <h4 className="text-dark mb-1 text-xl font-bold">Phone Number</h4>
                  <p className="text-body-color text-base">(+62)81 414 257 9980</p>
                </div>
              </div>
              <div className="mb-8 flex w-full max-w-[370px]">
                <div
                  className="bg-black text-black  mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-opacity-5 sm:h-[70px] sm:max-w-[70px]"
                >
                  <AiOutlineMail size={35} />
                </div>
                <div className="w-full">
                  <h4 className="text-dark mb-1 text-xl font-bold">Email Address</h4>
                  <p className="text-body-color text-base">info@techbsoftwares.com</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
};

export default Help;
