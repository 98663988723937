import React, { useState, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { BsArrowLeftShort } from "react-icons/bs"
import { Common } from "../Components";
import { getDeviceId } from "../Services/utils";
import * as WSConstants from "../Constants/websocket";
import * as Context from "../Contexts"

const PairDevice = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const { pairingCode, WSResponseHandler } = useContext(Context.MerchantContext);

    const deviceId = getDeviceId();
    const wsUrl = `${process.env.REACT_APP_WEBSOCKET_URL}/merchant/pair-device/${deviceId}/`;
    const onLoadMessage = {
        "type": WSConstants.MESSAGE_TYPES.GET_PAIRING_CODE
    }

    const onResponse = (data) => {
        setIsLoading(false);
        WSResponseHandler(data);
    }

    return (
        <Common.PreventAuthRoute>
            <Common.Websocket
                url={wsUrl}
                onMessage={(e) => onResponse(e)}
                isLoading={(e) => setIsLoading(e)}
                onLoadMessage={onLoadMessage}
            >
                <div className="flex flex-col items-center justify-top my-28 align-middle w-full gap-8 p-4 sm:p-3">
                    <div className="flex flex-col items-center justify-center gap-2">
                        <p className="text-gray-800 font-bold text-2xl sm:text-4xl">Authenticate Device</p>
                        <p className="text-gray-600 text-center">Login to partner admin portal and enter below code</p>
                    </div>

                    <div className="flex flex-col items-center justify-center gap-2">
                        <div className="flex">
                            {
                                pairingCode && !isLoading ?
                                    pairingCode.split('').map((digit, idx) => {
                                        return (
                                            <div
                                                key={idx}
                                                className="text-4xl p-4 sm:text-6xl sm:px-7 sm:p-5  text-gray-700 font-extrabold bg-white border border-gray-100"
                                            >
                                                {digit}
                                            </div>
                                        )
                                    }
                                    )
                                    : "Please wait..."
                            }
                        </div>
                        <div className="flex items-center text-center justify-center text-gray-500 font-light">
                            You will be taken to next screen automatically
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <button
                            onClick={() => navigate('/')}
                            className="flex items-center justify-center border rounded-md text-gray-700 my-2 py-2 px-8 text-center bg-secondary-200">
                            <BsArrowLeftShort size={20} className="mr-2" /> Go back
                        </button>
                    </div>
                </div>
            </Common.Websocket>
        </Common.PreventAuthRoute>
    )
}

export default PairDevice;