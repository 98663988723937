import React from "react";

const NoElements = ({text, children}) => {
    return (
        <div
            className="flex flex-col justify-center align-middle items-center h-[calc(100vh-80px)]"
        >
            {children}
            <p className="text-lg my-4">{text}</p>
        </div>
    )
}
export default NoElements;