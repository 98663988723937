import React, { useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import * as utils from "../../Utils";

const Bill = ({ orderNo, merchantName, orderBy, orderItems, orderTotal, created }) => {
    const billIframeId = `order-bill-${orderNo}`;

    const orderItemsTable = (
        <table style={{ width: "100%" }}>
            <tbody>
                <tr>
                    <th style={{ textAlign: "left" }}>Item</th>
                    <th>Subtotal</th>
                </tr>
                {
                    orderItems.map((item, idx) => {
                        return (
                            <tr key={item.id}>
                                <td>
                                    {`${item.quantity} x ${item.name}`}
                                    {item.customizations ? ` (${item.customizations})` : null}
                                </td>
                                <td style={{ textAlign: "center" }}>&#8377; {item.total_price}</td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )

    const BillDetails = (
        <div style={{ width: "70mm" }}>
            <div style={{ fontFamily: "monospace", fontSize: 16, breakAfter: "page" }}>
                <h3 style={{ width: "100%", textAlign: "center" }}>{merchantName}</h3>

                <hr />
                <div style={{ textAlign: "center" }}>
                    {utils.dateFormatter(created)}
                </div>
                <hr />

                <div style={{ display: "flex", flexDirection: "column" }}>
                    <span><b>Customer Name:</b> {orderBy}</span>
                    <span><b>Order No:</b> {orderNo}</span>
                </div>
                <hr />
                {orderItemsTable}
                <hr />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>Total</span><b>&#8377; {orderTotal}/-</b>
                </div>
                <hr />
                <div style={{ textAlign: 'center' }}>
                    <span>Thank you! Visit again.</span>
                </div>
            </div>
        </div>

    )

    useEffect(() => {
        const iframe = document.getElementById(billIframeId).contentWindow.document;
        iframe.open();
        iframe.write(ReactDOMServer.renderToString(BillDetails));
        iframe.close();
    })

    return (
        <iframe id={billIframeId} src="about:blank" className="hidden" />
    )
}

export default Bill;