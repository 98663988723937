import React, { useState, useEffect, useContext } from "react";
import * as billingService from "../../Services/billingServices";
import * as Context from "../../Contexts";
import * as Common from "../../Components/Common";
import { Billing as BillingC } from "../../Components/Admin";
import { TbFileInvoice } from "react-icons/tb";

const Billing = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [shouldReload, setReload] = useState(true);

  const {
    selectedMerchant
  } = useContext(Context.MerchantContext);


  const reload = () => {
    setReload(!shouldReload);
  };

  const Loader = () => {
    return (
      <div className="flex flex-col justify-center items-center h-[calc(100vh-72px)]">
        <Common.Spinner size={12} />
        <p className="text-base py-3">Loading please wait...</p>
      </div>
    )
  };

  useEffect(() => {
    if (selectedMerchant) {
      setIsLoading(true);
      billingService.getInvoices(selectedMerchant).then((response) => {
        setInvoices(response.data.results);
        setIsLoading(false);
      })
    }
  }, [selectedMerchant, shouldReload]);


  return (
    <div className="flex flex-col">
      <Common.SubHeader pageTitle={"Invoice"}></Common.SubHeader>

      <div className="p-8 h-[calc(100vh-140px)] overflow-y-scroll">
        {
          isLoading
            ? <Loader />
            : (invoices.length > 0
              ? <BillingC.InvoiceTable data={invoices} reloadHandler={reload} />
              : <Common.NoElements text="No Invoices">
                <TbFileInvoice size={100} />
              </Common.NoElements>)

        }
      </div>
    </div>
  );
};

export default Billing;
