import client from "./axiosConfig";

export const getQRCodes = async (merchantId) => {
    const config = {
        url: `/merchant/${merchantId}/qr/`,
        method: "GET"
    };
    const response = client(config);
    return response;
}

export const createQR = async (merchantId, labelName) => {
    const config = {
        url: `/merchant/${merchantId}/qr/`,
        method: "POST",
        data : {
            "label" : labelName,
            "merchant":merchantId
        }
    };
    const response = client(config);
    return response;
}

export const updateQR = async (qrId, merchantId, params) => {
    const config = {
        url: `/merchant/${merchantId}/qr/${qrId}/`,
        method: "PATCH",
        data : params
    };
    const response = client(config);
    return response;
}
