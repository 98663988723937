import React from "react";
import { MdOutlineFastfood } from "react-icons/md";

const NoOrders = () => {
    return (
        <div
            className="flex flex-col justify-center align-middle items-center h-[calc(100vh-160px)]"
        >
            <MdOutlineFastfood size={100} />
            <p className="text-lg my-4">No order</p>
        </div>
    )
}
export default NoOrders;