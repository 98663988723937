import React, { useContext } from "react";
import { Order } from "../../../Components/Admin";
import { ORDER_STATUSES } from "../../../Constants";
import * as Context from "../../../Contexts";

const OrderSummaryList = ({ orders }) => {
  const {
    selectedOrderSummaryId,
    setSelectedOrderSummaryId,
  } = useContext(Context.MerchantContext);

  const hasNewOrders = (orderSummary) => {
    // Check is order summary has any not attended orders
    const new_orders = orderSummary.orders.filter((order => {
      return order.status === ORDER_STATUSES.PLACED;
    }))
    return Boolean(new_orders.length);
  }

  const getSelectedStyle = (orderSummaryId) => {
    // Return style to highlight card is selected
    if (orderSummaryId === selectedOrderSummaryId) {
      return 'border border-2 border-secondary-100 '
    }
    return null;
  }

  return (
    <>
      {
        orders.map((orderSummary, idx) => {
          const orderSummaryId = `${orderSummary.qr_id}-${orderSummary.order_by_id}`;
          return (
            <div
              className={`cursor-pointer mb-2 shadow-sm bg-white ${getSelectedStyle(orderSummaryId)}`}
              onClick={() => setSelectedOrderSummaryId(orderSummaryId)}
              key={idx}
            >
              <Order.OrderSummaryCard
                QRLabel={orderSummary.qr_label}
                createdDate={orderSummary.placed_at}
                userName={orderSummary.order_by}
                itemCount={orderSummary.items_count}
                price={orderSummary.order_total}
                hasNewOrders={hasNewOrders(orderSummary)}
              />
            </div>
          )
        })
      }
    </>
  )
}
export default OrderSummaryList;