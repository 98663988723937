import React, {useContext} from "react";
import { Kitchen } from "../../Components";
import * as Context from "../../Contexts";
import * as WSConstants from "../../Constants/websocket";
import * as Constants from "../../Constants";


const Orders = () => {
    const {
        orders
      } = useContext(Context.MerchantContext);

    return (
        <Kitchen.Order.Orders eventType={WSConstants.MESSAGE_TYPES.ORDERS_LIST} 
            statuses={[Constants.ORDER_ITEM_STATUSES.NEW]} 
            orders={orders}/>
    )
}
export default Orders;