import React, { useState } from "react";
import { BiTrash } from "react-icons/bi";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import * as Common from "../../Common";

const AddEditTypeForm = ({ type, onSubmit, onDelete }) => {
    const [deleteType, setDeleteType] = useState(false);
    const [typeDetails, setTypeDetails] = useState({
        name: type?.name,
        max_options: type?.max_options,
        min_options: type?.min_options,
    });

    const DELETE_TYPE_MSG = "Are you sure you want to delete this customization type?"

    const validateAndSave = () => {
        if (typeDetails.name && typeDetails.max_options && typeDetails.min_options) {
            return onSubmit(typeDetails);
        } else {
            toast.error("Please enter required fields!");
        }
    }

    const onValueChange = (e) => {
        setTypeDetails({ ...typeDetails, [e.target.name]: e.target.value })
    }

    const removeTypeHandler = (isConfirm) => {
        if (isConfirm) {
            onDelete();
        }
        setDeleteType(false);
    }

    return (
        <div className="flex w-full justify-between gap-2 mb-2">
            <div className="w-3/6">
                <label className="text-xs font-light text-gray-500" htmlFor="name">Type Name</label>
                <input
                    type="text"
                    required={true}
                    id="name"
                    name="name"
                    value={typeDetails.name}
                    onChange={onValueChange}
                    className="w-full my-1 text-xs font-light bg-white rounded outline-none border border-slate-300 invalid:border-rose-500 invalid:focus:ring-0"
                ></input>
            </div>
            <div className="w-1/6">
                <label className="text-xs font-light text-gray-500" htmlFor="min_options">Min Options</label>
                <input
                    type="text"
                    required={true}
                    name="min_options"
                    min={1}
                    value={typeDetails.min_options}
                    onChange={onValueChange}
                    className="w-full my-1 text-xs font-light text-center bg-white rounded outline-none border border-slate-300 invalid:border-rose-500 invalid:focus:ring-0"
                ></input>
            </div>
            <div className="w-1/6">
                <label className="text-xs font-light text-gray-500" htmlFor="max_options">Max Options</label>
                <input
                    type="text"
                    required={true}
                    name="max_options"
                    value={typeDetails.max_options}
                    onChange={onValueChange}
                    className="w-full my-1 text-xs font-light text-center bg-white rounded outline-none border border-slate-300 disabled:border-none disabled:w-8 invalid:border-rose-500 invalid:focus:ring-0"
                ></input>
            </div>
            <div className="flex items-end justify-end w-1/6 ">
                <button
                    type="button"
                    onClick={validateAndSave}
                    className="h-8 w-full my-1 px-6 text-xs text-green-600 bg-green-200 rounded-md"
                >
                    Save
                </button>
                {
                    onDelete && <BiTrash
                        size={38}
                        onClick={() => setDeleteType(true)}
                        className="cursor-pointer text-red-400"
                    />
                }
                <Common.ConfirmPopup
                    show={deleteType}
                    title={"Delete type"}
                    body={DELETE_TYPE_MSG}
                    confirmHandler={removeTypeHandler}
                />
            </div>
        </div>
    );
}

AddEditTypeForm.propTypes = {
    type: PropTypes.shape({
        name: PropTypes.string,
        max_options: PropTypes.number,
        min_options: PropTypes.number,
    }),
    onSubmit: PropTypes.func,
}

export default AddEditTypeForm;
