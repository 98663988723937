import React, { forwardRef, useState, useImperativeHandle, useContext } from "react";
import toast from "react-hot-toast";
import * as userService from "../../../Services/userServices";
import * as Context from "../../../Contexts";
import { USER_ROLES } from "../../../Constants";

const AddUser = forwardRef((props, ref) => {

  const [user, setUser] = useState({
    "first_name": "",
    "last_name": "",
    "email": "",
    "role": ""
  });

  const [roles, setRoles] = useState([
    'MERCHANT_ADMIN',
    'MERCHANT_KITCHEN',
    'MERCHANT_CAPTAIN'
  ]);

  const { selectedMerchant } = useContext(Context.MerchantContext);

  useImperativeHandle(ref, () => ({
    addUser() {
      if (user.first_name && user.last_name && user.email && user.role) {
        userService.addMerchantUser(selectedMerchant, user).then((response) => {
          if (response.status === 201) {
            toast.success("User added succesfully");
          } else {
            toast.error("User already exists or Error!");
          }
          props.closePopup();
        })
      } else {
        toast.error("Enter valid user details");
      }
    }
  }));

  const onFieldValueChange = (e) => {
    console.log(e);
    const fieldName = e.target.name;
    const fieldType = e.target.type;
    let fieldValue = e.target.value;

    if (fieldType === 'checkbox') {
      fieldValue = e.target.checked;
    }
    setUser({ ...user, [fieldName]: fieldValue });
  };

  return (
    <>
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-1">
          <label className="text-sm text-gray-600"> First Name</label>
          <input
            type="text"
            required={true}
            name="first_name"
            defaultValue={user.first_name}
            onChange={onFieldValueChange}
            placeholder="Enter First Name"
            className="appearance-none rounded text-gray-700 focus:outline-none focus:shadow-outline p-3 text-sm w-80 bg-white outline-none border border-slate-300 disabled:border-none invalid:border-rose-500 invalid:focus:ring-0"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-sm text-gray-600"> Last Name</label>
          <input
            type="text"
            required={true}
            name="last_name"
            defaultValue={user.last_name}
            onChange={onFieldValueChange}
            placeholder="Enter Last Name"
            className="appearance-none rounded text-gray-700 focus:outline-none focus:shadow-outline p-3 text-sm w-80 bg-white outline-none border border-slate-300 disabled:border-none invalid:border-rose-500 invalid:focus:ring-0"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-sm text-gray-600"> First Name</label>
          <input
            type="text"
            required={true}
            name="email"
            defaultValue={user.email}
            onChange={onFieldValueChange}
            placeholder="Enter Email"
            className="appearance-none rounded text-gray-700 focus:outline-none focus:shadow-outline p-3 text-sm w-80 bg-white outline-none border border-slate-300 disabled:border-none invalid:border-rose-500 invalid:focus:ring-0"
          />
        </div>
        <div className="flex flex-col gap-1">
        <label className="text-sm text-gray-600"> User Role</label>
          <select
            id="roles"
            value={user.role}
            name="role"
            onChange={onFieldValueChange}
            required={true}
            className="border w-full p-3 text-gray-900 text-sm rounded invalid:border-rose-500 block"
          >
            <option value="" disabled>Choose a role</option>
            {
              USER_ROLES.map(role =>
                <option value={role.role} key={role.role}>
                  {role.title}
                </option>
              )
            }
          </select>
        </div>
      </div>
    </>
  )
});

export default AddUser;