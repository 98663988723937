import client from "./axiosConfig";

export const getMenuItems = (merchantId, params = {}) => {
  const config = {
    url: `/menu/${merchantId}/item/`,
    method: "GET",
    params: {
      limit: 300,
      ...params
    }
  };
  const response = client(config);
  return response;
};

export const getSpecificMenuItem = (merchantId, itemId) => {
  const config = {
    url: `/menu/${merchantId}/item/${itemId}/`,
    method: "GET",
  };
  const response = client(config);
  return response;
};


export const getMenuItemCustomizations = (merchantId, itemId) => {
  const config = {
    url: `/menu/${merchantId}/item/${itemId}/customization-type/`,
    method: "GET",
    params: {
      limit: 200,
    }
  };
  const response = client(config);
  return response;
};

export const getCategories = (merchantId, params = {}) => {
  const config = {
    url: `/menu/${merchantId}/category/`,
    method: "GET",
    params: {
      limit: 300,
      ...params
    }
  };
  const response = client(config);
  return response;
};

export const addMenuCategory = (merchantId, categoryName) => {
  const config = {
    url: `/menu/${merchantId}/category/`,
    method: "POST",
    data: {
      name: categoryName,
      is_active: true,
      merchant: merchantId
    }
  };
  const response = client(config);
  return response;
};

export const updateMenuCategory = (merchantId, categoryId, params = {}) => {
  const config = {
    url: `/menu/${merchantId}/category/${categoryId}/`,
    method: "PATCH",
    data: {
      merchant: merchantId,
      ...params
    }
  };
  const response = client(config);
  return response;
};

export const deleteMenuCategory = (merchantId, categoryId, params = {}) => {
  const config = {
    url: `/menu/${merchantId}/category/${categoryId}/`,
    method: "DELETE"
  };
  const response = client(config);
  return response;
};

export const addMenuItems = (merchantId, params = {}) => {
  const config = {
    url: `/menu/${merchantId}/item/`,
    method: "POST",
    data: {
      is_active: true,
      ...params
    }
  };
  const response = client(config);
  return response;
};

export const editMenuItems = (merchantId, itemId, params = {}) => {
  const config = {
    url: `/menu/${merchantId}/item/${itemId}/`,
    method: "PATCH",
    data: {
      ...params
    }
  };
  const response = client(config);
  return response;
};

export const uploadMenuItemImage = (merchantId, itemId, formData) => {
  const config = {
    url: `/menu/${merchantId}/item/${itemId}/`,
    method: "PATCH",
    header: {
      "Content-Type": "multipart/form-data;"
    },
    data: formData
  };
  const response = client(config);
  return response;
};

export const deleteMenuItems = (merchantId, itemId) => {
  const config = {
    url: `/menu/${merchantId}/item/${itemId}/`,
    method: "DELETE"
  };
  const response = client(config);
  return response;
};

export const deleteCustomizationType = (merchantId, itemId, typeId) => {
  const config = {
    url: `/menu/${merchantId}/item/${itemId}/customization-type/${typeId}/`,
    method: "DELETE"
  };
  const response = client(config);
  return response;
};

export const addCustomizationType = (merchantId, itemId, params = {}) => {
  const config = {
    url: `/menu/${merchantId}/item/${itemId}/customization-type/`,
    method: "POST",
    data: {
      ...params
    }
  };
  const response = client(config);
  return response;
}

export const updateCustomizationType = (merchantId, itemId, typeId, params = {}) => {
  const config = {
    url: `/menu/${merchantId}/item/${itemId}/customization-type/${typeId}/`,
    method: "PATCH",
    data: {
      ...params
    }
  };
  const response = client(config);
  return response;
}

export const getSpecificCustomizationType = (merchantId, itemId, typeId) => {
  const config = {
    url: `/menu/${merchantId}/item/${itemId}/customization-type/${typeId}/`,
    method: "GET",
  };
  const response = client(config);
  return response;
}


export const addCustomizationOption = (params = {}) => {
  const config = {
    url: `/menu/customization-option/`,
    method: "POST",
    data: {
      ...params
    }
  };
  const response = client(config);
  return response;
};

export const updateCustomizationOption = (optionId, params = {}) => {
  const config = {
    url: `/menu/customization-option/${optionId}/`,
    method: "PATCH",
    data: {
      ...params
    }
  };
  const response = client(config);
  return response;
};

export const deleteCustomizationOption = (optionId) => {
  const config = {
    url: `/menu/customization-option/${optionId}/`,
    method: "DELETE"
  };
  const response = client(config);
  return response;
};