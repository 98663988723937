import { Navigate } from 'react-router-dom';
import { validateUser, clearLocalStorage, getUser } from "../../Services/utils";
import { ADMIN_HOME, KICTHEN_HOME } from "../../Constants/routes";
import * as Constants from "../../Constants";

const PreventAuthRoute = ({ children }) => {
  const isUserValid = validateUser();

  if (isUserValid) {
    const user = getUser();
    switch (user.role) {
      case Constants.USER_ROLE_ADMIN:
        return <Navigate to={ADMIN_HOME} replace={true} />

      case Constants.USER_ROLE_KITCHEN:
        return <Navigate to={KICTHEN_HOME} replace={true} />

      default:
        break;
    }
  }

  return children;
}

export default PreventAuthRoute;