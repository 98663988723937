import React, { useContext, useState, useEffect, useRef } from "react";
import * as Common from "../../Common";
import * as menuService from "../../../Services/menuService";
import * as Context from "../../../Contexts/merchantContext";
import toast from "react-hot-toast";


const MenuDetails = ({
    onSubmit,
    onCancel,
    removeItemImage = {},
    itemName = "",
    categoryId = "",
    price = "",
    hasCustomization = false,
    isVeg = false,
    description = "",
    image = "",
    loading = false,
}) => {
    const [itemDetails, setItemDetails] = useState({
        name: itemName,
        category: categoryId,
        price: price,
        has_customization: hasCustomization,
        is_veg: isVeg,
        description: description,
    })
    const [categories, setCategories] = useState([]);
    const { selectedMerchant, refresh } = useContext(Context.MerchantContext);
    const itemImageRef = useRef();

    const onFieldValueChange = (e) => {
        const fieldName = e.target.name;
        const fieldType = e.target.type;
        let fieldValue = e.target.value;

        if (fieldType === 'checkbox') {
            fieldValue = e.target.checked;
        }

        setItemDetails({ ...itemDetails, [fieldName]: fieldValue })
    };

    const uploadImage = (e) => {
        const selectedImage = e.target.files[0]
        setItemDetails({ ...itemDetails, "image": selectedImage });
    };

    const validateAndSave = () => {
        if (itemDetails.name && itemDetails.category && itemDetails.price) {
            onSubmit(itemDetails);
        } else {
            toast.error("Enter Menu Details");
        }
    };

    useEffect(() => {
        setItemDetails({
            name: itemName,
            category: categoryId,
            price: price,
            has_customization: hasCustomization,
            is_veg: isVeg,
            description: description,
        })
    }, [itemName])

    useEffect(() => {
        menuService.getCategories(selectedMerchant).then((response) => {
            if (response.status === 200) {
                setCategories(response.data.results);
            }
        });
    }, [selectedMerchant, itemName, refresh])

    return (
        <div>
            <div className="flex item-center justify-between text-gray-600 bg-white p-4">
                <div className="flex items-center text-sm font-medium justify-center">
                    <span>Basic Information</span>
                </div>
                <div className="flex items-left justify-end gap-2">
                    <button
                        type="button"
                        onClick={() => validateAndSave()}
                        className="h-8 px-6 text-xs text-green-600 bg-green-200 disabled:bg-green-100 rounded-md"
                        disabled={loading}
                    >
                        Save
                    </button>
                    <button
                        type="button"
                        onClick={onCancel}
                        className="h-8 px-6 text-xs text-red-600 bg-red-200 rounded-md"
                    >
                        Cancel
                    </button>
                </div>
            </div>

            <div className="flex flex-col bg-white p-4 my-1 text-sm text-gray-500">
                <div className="flex flex-row justify-between gap-4">
                    <div className="w-1/2">
                        <p>Name</p>
                        <input
                            type="text"
                            required={true}
                            name="name"
                            value={itemDetails.name}
                            onChange={onFieldValueChange}
                            className="p-2 my-2 text-sm w-full bg-white rounded outline-none border border-slate-300 disabled:border-none disabled:w-8 invalid:border-rose-500 invalid:focus:ring-0"
                        ></input>
                    </div>
                    <div className="w-1/2">
                        <p>Category</p>
                        <select
                            id="categories"
                            value={itemDetails.category}
                            name="category"
                            onChange={onFieldValueChange}
                            required={true}
                            className="p-2 my-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 invalid:border-rose-500 block w-full"
                        >
                            <option value="">Choose a category</option>
                            {
                                categories.map(category => {
                                    return <option value={category.id} key={category.id}>{category.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>

                <div className="flex flex-row mt-2 items-center justify-between">
                    <div>
                        <p>Price</p>
                        <input
                            type="number"
                            required={true}
                            name="price"
                            onChange={onFieldValueChange}
                            value={itemDetails.price}
                            className="p-2 my-2 text-sm bg-white rounded outline-none border border-slate-300 disabled:border-none disabled:w-8 invalid:border-rose-500 invalid:focus:ring-0"
                        ></input>
                    </div>
                    <div>
                        <Common.Toggle
                            name="has_customization"
                            onToggleChange={onFieldValueChange}
                            toggleName={'Has Customization'}
                            toggleStatus={itemDetails.has_customization}
                        ></Common.Toggle>
                    </div>
                    <div>
                        <Common.Toggle
                            name="is_veg"
                            onToggleChange={onFieldValueChange}
                            toggleName={'Is Veg'}
                            toggleStatus={itemDetails.is_veg}
                        ></Common.Toggle>
                    </div>
                </div>

                <div className="flex flex-row gap-4 mt-2">
                    <div className="w-1/2">
                        <p>Description</p>
                        <textarea
                            name="description"
                            onChange={onFieldValueChange}
                            rows={4}
                            value={itemDetails.description}
                            className="p-2 my-2 w-full text-sm bg-white rounded outline-none border border-slate-300 disabled:border-none disabled:w-8 invalid:border-rose-500 invalid:focus:ring-0"
                        ></textarea>
                    </div>
                    <div className="w-1/2">
                        <p>Image</p>
                        <div className="flex flex-col gap-2 my-2">
                            {
                                image
                                    ? <div className="flex gap-3">
                                        <img src={image} alt={itemDetails.name} className="w-20 h-20 mb-1" />
                                        <button
                                            type="button"
                                            onClick={removeItemImage}
                                            className="h-8 px-3 text-xs text-gray-900 bg-white rounded-lg border border-gray-200"
                                        >
                                            Remove Image
                                        </button>
                                    </div>
                                    : <div>
                                        <input
                                            type="file"
                                            className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="file_input_help"
                                            id="file_input"
                                            key={itemDetails.name}
                                            ref={itemImageRef}
                                            onChange={uploadImage} />
                                        <p className="mt-1 text-sm text-gray-500 font-light" id="file_input_help">PNG and JPG only, 1:1 Aspect ratio and max size 800x800px.</p>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MenuDetails;