import React from "react";
import * as toast from 'react-hot-toast';

const Toaster = () => {
    const toastOptions = {
        className: '',
        duration: 3000,
        success: {
            icon: '✅'
        },
        error: {
            error: '❌'
        }
    }

    return (
        <toast.Toaster
            position="top-center"
            containerClassName="mt-8 text-sm mx-4 p-2"
            toastOptions={toastOptions}
        />
    )
}

export default Toaster;