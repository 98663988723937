import UserTableRow from "./UserTableRow";

const UserTable = ({ data, reloadHandler }) => {

  return (
    <>
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="p-4 w-40"> First Name </th>
              <th scope="col" className="p-4 w-40"> Last Name </th>
              <th scope="col" className="p-4 w-40"> Email </th>
              <th scope="col" className="p-4 w-40"> Role </th>
              <th scope="col" className="p-4 w-40"> Action </th>
            </tr>
          </thead>
          <tbody>
            {
              data.map(user => {
                return (
                  <UserTableRow
                    userId = {user.id}
                    merchantUserId={user.merchant_user_id}
                    first_name={user.first_name}
                    last_name={user.last_name}
                    email={user.email}
                    role={user.role}
                    key={user.id}
                    reloadHandler={reloadHandler}
                  />
                )
              })
            }
          </tbody>
        </table>
      </div>
    </>
  )
}

export default UserTable;