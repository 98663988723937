import client from "./axiosConfig";

export const getInvoices = async (merchantId) => {
    const config = {
        url: `/billing/invoice/`,
        method: "GET",        
        params: {
            merchant: merchantId,
        },
    };
    const response = client(config);
    return response;
}

export const getInvoiceById = async (invoiceId, merchantId) => {
    const config = {
        url: `/billing/invoice/${invoiceId}/`,
        method: "GET",
        params: {
            merchant: merchantId,
        },
    };
    const response = client(config);
    return response;
}

export const createPaymentOrder = async (invoiceId) => {
    const config = {
        url: '/billing/pay/',
        method: "POST",
        data: {
            invoice_id: invoiceId,
        },
    };
    const response = client(config);
    return response;
}


