export const RETRY_TIME = 5000;

export const MESSAGE_TYPES = {
    ORDERS_LIST: 'orders_list',
    COMPLETED_ORDERS_LIST : 'completed_orders_list',
    GET_PAIRING_CODE: 'get_pairing_code',
    PAIRING_AUTH_SUCCESS: 'auth_success',
}

export const STATUS_MESSAGES = {
    CONNECTED: 'Open',
    CONNECTING: 'Connecting...',
    CLOSED: `Connection lost! Retrying in ${RETRY_TIME / 1000} seconds.`,
    CLOSING: 'Disconnecting...',
    UNINSTANTIATED: 'Please wait!'
}
