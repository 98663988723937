import QRTableRow from "./QRTableRow";

const QRTable = ({ data, reloadHandler }) => {

  return (
    <>
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="py-3 px-6 w-[250px]"> Name </th>
              <th scope="col" className="py-3 px-6 w-44"> Status </th>
              <th scope="col" className="py-3 px-6 w-44"> Last Updated</th>
              <th scope="col" className="py-3 px-6 w-full"> Action </th>
            </tr>
          </thead>
          <tbody>
            {
              data.map(qr => {
                return (
                  <QRTableRow
                    labelId={qr.id}
                    label={qr.label}
                    isDefault={qr.is_default}
                    merchantId={qr.merchant}
                    isActive={qr.is_active}
                    updated={qr.updated}
                    key={qr.id}
                    reloadHandler={reloadHandler}
                  />
                )
              })
            }
          </tbody>
        </table>
      </div>
    </>
  )
}

export default QRTable;