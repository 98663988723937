import React from "react";
import * as Constants from "../../../Constants";

const OrderStatusBadge = ({status}) => {
    const _status = Constants.ORDER_STATUS_STYLE[status];
    return (
        <p className={`${_status.styleClasses}`}>
            {_status.title}
        </p>
    )
}
export default OrderStatusBadge;
