import client from "./axiosConfig";

export const getMerchantUsers = async (merchantId) => {
  const config = {
    url: `/merchant/${merchantId}/users/`,
    method: "GET"
  };
  const response = client(config);
  return response;
}

export const updateMerchantUser = (merchantId, userId, params = {}) => {
  const config = {
    url: `/merchant/${merchantId}/users/${userId}/`,
    method: "PATCH",
    data: params
  };
  const response = client(config);
  return response;
};

export const deleteMerchantUser = (merchantId, userId) => {
  const config = {
    url: `/merchant/${merchantId}/users/${userId}/`,
    method: "DELETE"
  };
  const response = client(config);
  return response;
};

export const addMerchantUser = (merchantId, params = {}) => {
  const config = {
    url: `/merchant/${merchantId}/users/`,
    method: "POST",
    data: params
  };
  const response = client(config);
  return response;
};

export const addDevice = (params = {}) => {
  const config = {
    url: `/merchant/pair-device/`,
    method: "POST",
    data: params
  };
  const response = client(config);
  return response;
};

