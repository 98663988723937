import React from "react";
import { Footer, PublicHeader } from "../Common";
import { Outlet } from "react-router-dom";

const PublicLayout = () => {
  return (
    <div>
      <PublicHeader />
      <main className="min-h-screen bg-slate-100 px-2 sm:px-4 py-6">
        <Outlet />
      </main>
      <Footer />
    </div>
  )
};
export default PublicLayout;