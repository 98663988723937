import React from "react";
import { BiDish, BiHistory, BiUser } from "react-icons/bi";
import { CgMenuBoxed } from "react-icons/cg";
import { MdOutlineQrCodeScanner, MdOutlineHelpOutline } from "react-icons/md";
import { BsGear } from "react-icons/bs";
import { TbFileInvoice } from "react-icons/tb";
import * as routes from "../../Constants/routes";

const SideBar = () => {

    const navigationItem = (title, path, icon) => {
        const isItemActive = window.location.pathname === path;
        return <li>
            <a
                href={path}
                className={`flex items-center ${isItemActive ? 'bg-gray-100' : null} p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100  group`}
            >
                {icon}
                <span className="ml-3">{title}</span>
            </a>
        </li>;
    }

    return (
        <aside aria-label="Sidenav">
            <div className="overflow-y-auto py-5 px-3 bg-white">
                <ul className="space-y-2">
                    {
                        navigationItem("Orders", routes.ORDERS, <BiDish size={25} color="gray" />)
                    }
                    {
                        navigationItem("Order History", routes.ORDER_HISTORY, <BiHistory size={25} color="gray" />)
                    }
                    {
                        navigationItem("Menu", routes.MENU, <CgMenuBoxed size={25} color="gray" />)
                    }
                    {
                        navigationItem("QR Codes", routes.QR_CODES, <MdOutlineQrCodeScanner size={25} color="gray" />)
                    }
                    {
                        navigationItem("Users", routes.USERS, <BiUser size={25} color="gray" />)
                    }
                </ul>
                <ul className="pt-5 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700">
                    {/* {
                        navigationItem("Docs", "/", <HiOutlineClipboardList size={25} color="gray" />)
                    } */}
                    {
                        navigationItem("Billing", routes.BILLING, <TbFileInvoice size={25} color="gray" />)
                    }
                    {
                        navigationItem("Help", routes.HELP, <MdOutlineHelpOutline size={25} color="gray" />)
                    }
                </ul>
            </div>
        </aside>
    )
}

export default SideBar;