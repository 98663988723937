import client from "./axiosConfig";

export const getMyMerchants = () => {
    const config = {
        url: "/merchant/",
        method: 'GET',
        params: {
            is_working: true,
        },
    }
    const response = client(config);
    return response;
}

export const getSelectedMerchant = () =>{
    return localStorage.getItem('mId');
}

export const setSelectedMerchant = (merchantId) =>{
    return localStorage.setItem('mId', merchantId);
}
