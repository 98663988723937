import React, { useEffect, useState } from "react";
import { BsClockHistory } from "react-icons/bs";

const TimeElapsed = ({ datetime }) => {
    const [time, setTime] = useState('00 : 00');

    function secondsToHhmm(totalSeconds) {
        let hours = Math.floor(totalSeconds / 3600);
        let minutes = Math.floor((totalSeconds % 3600) / 60);

        // Add leading zeroes
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return hours + ' : ' + minutes;
    }


    useEffect(() => {
        const _minute =
            setInterval(() => {
                const currDate = new Date()
                const orderDate = new Date(datetime)
                const totalSeconds = Math.abs(currDate - orderDate) / 1000;

                setTime(secondsToHhmm(totalSeconds))
            }, 1000);
        return () => clearInterval(_minute);
    }, [time]);

    return (
        <div className="text-right text-sm">
            <p className="flex items-center justify-end gap-1 text-xs text-gray-500 font-light">
                <BsClockHistory />hh : mm
            </p>
            <p>{time}</p>
        </div>
    )
}
export default TimeElapsed;