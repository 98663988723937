import React, { useState, useEffect, useContext, useRef } from "react";
import {
    AiOutlineDelete,
    AiOutlineDownload,
    AiOutlineQrcode,
    AiOutlineEdit,
} from "react-icons/ai";
import { saveAs } from "file-saver";
import QRCode from 'qrcode';
import { Common } from "../../../Components";

import * as qrService from "../../../Services/qrServices";
import * as Context from "../../../Contexts";
import * as utils from "../../../Utils";


const QRTableRow = ({ labelId, label, merchantId, isDefault, isActive, reloadHandler, updated }) => {
    const [editQrLabel, setEditQrLabel] = useState(false);
    const [isQrLabelValid, setIsQrLabelValid] = useState(true);
    const [qrLabel, setQrLabel] = useState(label);
    const [qrCode, setQrCode] = useState(null);
    const [showDeleteWarning, setDeleteWarning] = useState(false);
    const [showQR, setShowQR] = useState(false);
    const [qrStatus, setQRStatus] = useState(isActive);
    const qrLabelRef = useRef(null);

    const {
        selectedMerchant
    } = useContext(Context.MerchantContext);

    const dateOptions = {
        hour: 'numeric',
        minute: 'numeric',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour12: true,
    };

    const updateQrLabel = () => {
        setEditQrLabel(false);
        let params = {
            "label": qrLabel
        };
        qrService.updateQR(labelId, merchantId, params).then((response) => {
            if (response.status === 200) {
                setQrLabel(response.data.label);
                reloadHandler();
            }
        });
    }

    const deleteQRCode = () => {
        let params = {
            "is_deleted": true
        };
        qrService.updateQR(labelId, merchantId, params).then((response) => {
            if (response.status === 200) {
                setDeleteWarning(false);
                reloadHandler();
            }
        });
    }

    const qrLabelChangeWatch = (e) => {
        let value = e.target.value;
        if (value.length >= 3) {
            setQrLabel(value);
            setIsQrLabelValid(true);
        } else {
            setIsQrLabelValid(false);
        }

        if (e.key === 'Enter' && isQrLabelValid) {
            updateQrLabel();
        }
    };

    const downloadFile = () => {
        saveAs(qrCode, "QR_" + qrLabel + ".png");
    };

    const toggleQRStatus = () => {
        let params = {
            "is_active": !qrStatus
        };
        qrService.updateQR(labelId, merchantId, params).then((response) => {
            if (response.status === 200) {
                setQRStatus(response.data.is_active);
            }
        });
    };


    useEffect(() => {
        let value = `${process.env.REACT_APP_USER_APP_BASE_URL}/merchant/${selectedMerchant}?qId=${labelId}`;
        QRCode.toDataURL(value, {
            width: 500,
            margin: 1
        }).then(url => {
            setQrCode(url);
        })

        return () => {
            setQrCode(null);
        }
    }, [labelId])


    useEffect(() => {
        if (qrLabelRef.current) {
            qrLabelRef.current.focus();
        }
    }, [editQrLabel])

    const qrActions = () => {
        return (
            <>
                {
                    !editQrLabel && <>
                        <button
                            type="button"
                            disabled={!isQrLabelValid}
                            onClick={() => setEditQrLabel(true)}
                            className="h-8 px-3 text-xs text-gray-600 bg-white rounded-md border border-gray-200 disabled:text-gray-300"
                        >
                            <div className="flex items-center">
                                <AiOutlineEdit className="mr-1" /> Edit
                            </div>
                        </button>
                        <button
                            type="button"
                            onClick={() => setShowQR(true)}
                            className="h-8 px-3 text-xs text-gray-600 bg-white rounded-md border border-gray-200 disabled:text-gray-300"
                        >
                            <div className="flex items-center">
                                <AiOutlineQrcode className="mr-1" /> View QR
                            </div>
                        </button>
                        <Common.Modal
                            show={showQR}
                            cancelHandler={() => setShowQR(false)}
                            title={'QR ' + qrLabel}
                        >
                            <Common.ViewQR qrCode={qrCode} height={200} width={200} />
                        </Common.Modal>
                        <button
                            type="button"
                            onClick={downloadFile}
                            className="h-8 px-3 text-xs text-gray-600 bg-white rounded-md border border-gray-200 disabled:text-gray-300"
                        >
                            <div className="flex items-center">
                                <AiOutlineDownload className="mr-1" /> Download QR
                            </div>
                        </button>
                        <button
                            type="button"
                            disabled={isDefault}
                            onClick={() => setDeleteWarning(true)}
                            className="h-8 px-3 text-xs text-gray-600 bg-white rounded-md border border-gray-200 disabled:text-gray-300"
                        >
                            <div className="flex items-center">
                                <AiOutlineDelete color="red" className="mr-1" />Delete
                            </div>
                        </button>
                        <Common.Modal
                            show={showDeleteWarning}
                            cancelHandler={() => setDeleteWarning(false)}
                            confirmHandler={deleteQRCode}
                            title={'Delete QR'}
                        >
                            <p className="text-sm text-gray-700">
                                Are you sure? You want to delete '{label}'.
                            </p>
                        </Common.Modal>
                    </>
                }
                {
                    editQrLabel &&
                    <button
                        type="button"
                        disabled={!isQrLabelValid}
                        onClick={updateQrLabel}
                        className="h-8 px-3 text-xs text-gray-600 bg-white rounded-md border border-gray-200 disabled:text-gray-300"
                    >
                        Save
                    </button>
                }
            </>
        )
    }

    return (
        <>
            <tr className="bg-white border-b hover:bg-gray-50">
                <th scope="row" className="flex items-center w-[250px] p-4 font-medium text-gray-900 whitespace-nowrap">
                    <div className="text-base font-semibold">
                        {
                            editQrLabel
                                ? <input
                                    type="text"
                                    required={true}
                                    ref={qrLabelRef}
                                    defaultValue={qrLabel}
                                    id={labelId}
                                    disabled={!editQrLabel}
                                    onKeyUp={qrLabelChangeWatch}
                                    className="px-2 py-1 text-sm w-56 bg-white rounded outline-none border border-slate-300 disabled:border-none invalid:border-rose-500 invalid:focus:ring-0"
                                />
                                : <p className="px-2 py-1 text-sm w-56 truncate ...">{qrLabel}</p>
                        }
                    </div>
                </th>
                <td className="py-4 px-6 w-44">
                    <div className="flex items-center w-36">
                        <Common.Toggle
                            toggleName={qrStatus ? "Enabled" : "Disabled"}
                            toggleStatus={qrStatus}
                            onToggleChange={toggleQRStatus}
                            key={labelId}
                        />
                    </div>
                </td>
                <td className="py-4 px-6 w-44">
                    <div className="flex items-center w-44">
                        {utils.dateFormatter(updated, dateOptions)}
                    </div>
                </td>
                <td className="py-4 px-6 flex space-x-3">
                    {qrActions()}
                </td>
            </tr>
        </>
    )
}

export default QRTableRow;