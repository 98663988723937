import React, { useContext, useEffect, useState } from "react";
import * as orderService from "../../../Services/orderServices";
import { Common } from "../../../Components";
import { Order } from "../../../Components/Admin";
import * as Constants from "../../../Constants";
import * as Context from "../../../Contexts";
import * as utils from "../../../Utils";
import * as _ from "lodash";


const OrderDetailSection = ({ order }) => {
    const [markOrdersComplete, setMarkOrdersComplete] = useState(false);
    const [latestOrderId, setLastOrderId] = useState(null);
    const BILL_IFRAME_ID = `order-bill-${latestOrderId}`;

    const { merchants, selectedMerchant } = useContext(Context.MerchantContext);
    let merchant = _.find(merchants, { id: selectedMerchant });

    const printBill = (BILL_IFRAME_ID) => {
        window.frames[BILL_IFRAME_ID].contentWindow.print()
    }

    const markAllOrdersComplete = async () => {
        const orderIds = order.orders.map(order => order.id)
        await orderService.bulkOrderStatusUpdate(orderIds, Constants.ORDER_STATUSES.COMPLETED)
        setMarkOrdersComplete(false);
    }

    const showMarkComplete = () => {
        // Check is order summary has any not attended orders
        const accepted_orders = order.orders.filter((order => {
            return order.status === Constants.ORDER_STATUSES.ACCEPTED;
        }))

        return accepted_orders.length === order.orders.length;
    }

    useEffect(() => {
        if (order) {
            const _latestOrderId = order.orders[0].order_id;
            setLastOrderId(_latestOrderId);
        }
    })

    const orderActions = () => {
        return (
            <>
                <div className="flex flex-col gap-1 text-sm">
                    <span>
                        <span>Total bill &#8377; {order.order_total}</span>
                        <span className="mx-2">|</span>
                        <span>{order.items_count} Items</span>
                    </span>
                    <span className="text-gray-500 text-xs">
                        Orders from {order.qr_label} by {order.order_by}
                    </span>
                </div>
                <div className="space-x-2">
                    <button
                        type="button"
                        onClick={() => printBill(BILL_IFRAME_ID)}
                        className="h-8 px-3 text-xs text-gray-900 bg-white rounded-lg border border-gray-200"
                    >
                        Print Bill
                    </button>
                    {
                        showMarkComplete() &&
                        <>
                            <button
                                type="button"
                                onClick={() => setMarkOrdersComplete(true)}
                                className="h-8 px-3 text-xs text-gray-900 bg-white rounded-lg border border-gray-200"
                            >
                                Mark Complete
                            </button>
                            <Common.Modal
                                show={markOrdersComplete}
                                cancelHandler={() => setMarkOrdersComplete(false)}
                                confirmHandler={markAllOrdersComplete}
                                title={'Mark orders complete'}
                            >
                                <p className="text-sm text-gray-700">
                                    Are you sure? You want to mark all orders complete.
                                </p>
                            </Common.Modal>
                        </>
                    }
                </div>
                <Common.Bill
                    orderNo={latestOrderId}
                    merchantName={merchant.name}
                    created={utils.dateFormatter(order.placed_at)}
                    orderBy={order.order_by}
                    orderItems={utils.getOrderItemsList(order.orders)}
                    orderTotal={order.order_total}
                    key={latestOrderId}
                />
            </>
        )
    }

    return (
        <>
            {
                order ? (
                    <div className="flex flex-col" >
                        <div className="flex justify-between w-full bg-white p-3 text-sm shadow-md z-40">
                            {orderActions()}
                        </div>
                        <div className="flex flex-col h-[calc(100vh-202px)] overflow-y-scroll pt-2">
                            {
                                order.orders.map(orderDetails =>
                                    <Order.OrderCard
                                        id={orderDetails.id}
                                        order_id={orderDetails.order_id}
                                        order_by={order.order_by}
                                        qr_label={order.qr_label}
                                        total_price={orderDetails.total_price}
                                        note={orderDetails.note}
                                        status={orderDetails.status}
                                        items={orderDetails.items}
                                        key={orderDetails.id}
                                        created={orderDetails.created}
                                    />
                                )
                            }
                        </div>
                    </div >
                ) : <Order.NoOrderSelected />
            }
        </>
    )

}

export default OrderDetailSection;