import React, { useState, useRef, useEffect } from "react";
import { BiTrash, BiCheck } from "react-icons/bi";
import { TbEdit } from "react-icons/tb";
import { Common } from "../../../Components";
import * as orderService from "../../../Services/orderServices";
import { ORDER_ITEM_STATUSES, ORDER_STATUSES } from "../../../Constants";

const OrderItemRow = ({ id, name, quantity, has_customization, customizations, total_price, orderStatus, itemStatus }) => {
    const [deleteOrderItem, setDeleteOrderItem] = useState(false);
    const [editOrderItem, seteditOrderItem] = useState(false);
    const [isQuantityValid, setIsQuantityValid] = useState(true);
    const [itemQuantity, setItemQuantity] = useState(quantity);
    const itemQuantityRef = useRef(null);

    const DELETE_ITEM_MSG = `Are you sure you want to delete '${quantity} X ${name}'?`;
    const ORDER_QUANTITY_INPUT_ID = `order-item-quantity-${id}`;

    const removeItemHandler = async (isConfirm) => {
        if (isConfirm) {
            await orderService.removeItemFromOrder(id);
        }
        setDeleteOrderItem(false);
    }

    const updateOrderItemQuntity = async () => {
        orderService.updateItemQuantityToOrder(id, itemQuantity);
        seteditOrderItem(false);
    }

    const orderItemQuntityChangeWatch = (e) => {
        let value = Number(e.target.value);
        if (100 < value || value < 1) {
            setIsQuantityValid(false);
        } else {
            setItemQuantity(value)
            setIsQuantityValid(true);
        }

        if (e.key === 'Enter' && isQuantityValid) {
            updateOrderItemQuntity();
        }
    }

    useEffect(() => {
        if (itemQuantityRef.current) {
            itemQuantityRef.current.focus();
        }
    }, [editOrderItem])

    return (
        <div className="flex justify-between items-center py-1 text-sm" key={id}>
            <div className="flex items-left flex-col">
                <div className="font-semibold flex  justify-start items-center">
                    <input
                        min="1"
                        max="100"
                        type="number"
                        required={true}
                        ref={itemQuantityRef}
                        defaultValue={itemQuantity}
                        id={ORDER_QUANTITY_INPUT_ID}
                        disabled={!editOrderItem}
                        onKeyUp={orderItemQuntityChangeWatch}
                        className="p-1 text-sm text-center w-12 bg-white rounded outline-none border border-slate-300 disabled:border-none disabled:w-8 invalid:border-rose-500 invalid:focus:ring-0"
                    />
                    <span className="text-gray-500 font-normal px-2">x</span>
                    <div className="flex gap-2 justify-start items-center">
                        <span>{name}</span>
                        {itemStatus === ORDER_ITEM_STATUSES.READY && <BiCheck className="text-green-600 cursor-pointer" size={20} title="Item ready"></BiCheck>}
                    </div>
                </div>
                {
                    has_customization &&
                    <p className="text-xs text-gray-700 ml-14">{customizations}</p>
                }
            </div>
            <div className="flex items-center space-x-2 text-gray-700">
                <p>&#8377; {total_price}</p>
                {
                    [
                        ORDER_STATUSES.ACCEPTED,
                        ORDER_STATUSES.PLACED,
                    ].includes(orderStatus) &&
                    <>
                        {
                            !editOrderItem ?
                                <>
                                    <BiTrash
                                        size={15}
                                        onClick={() => setDeleteOrderItem(true)}
                                        className="cursor-pointer text-red-400"
                                    />
                                    <TbEdit
                                        size={16}
                                        onClick={() => seteditOrderItem(true)}
                                        className="cursor-pointer text-gray-500"
                                    />
                                    <Common.ConfirmPopup
                                        show={deleteOrderItem}
                                        title={"Delete item"}
                                        body={DELETE_ITEM_MSG}
                                        confirmHandler={removeItemHandler}
                                    />
                                </>
                                : <button
                                    type="button"
                                    disabled={!isQuantityValid}
                                    onClick={() => updateOrderItemQuntity()}
                                    className="h-8 px-3 text-xs text-gray-600 bg-white rounded-md border border-gray-200 disabled:text-gray-300"
                                >
                                    Done
                                </button>
                        }
                    </>
                }
            </div>
        </div>
    )
}
export default OrderItemRow;