import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import * as userService from "../../Services/utils";
import * as merchantService from "../../Services/merchantService";
import * as context from "../../Contexts";
import MerchantSelector from "./MerchantSelector"

const Header = ({ showMarchantSelector = true, disbaleMerchantSelector = false }) => {
  const navigate = useNavigate();
  const user = userService.getUser();
  const {
    setOrders,
    merchants,
    selectedMerchant,
    setSelectedMerchant,
    setSelectedOrderSummaryId
  } = useContext(context.MerchantContext);

  const getUserInitials = (name) => {
    let initials = name.split(" ").map((n) => n[0]).join("");
    if (!initials) {
      return "MA";
    }
    return initials;
  }

  const handleSignout = () => {
    userService.clearLocalStorage();
    navigate("/");
  }

  const merchantSelectHandler = (merchantId) => {
    setOrders([]);
    setSelectedOrderSummaryId(null);
    setSelectedMerchant(merchantId);
    merchantService.setSelectedMerchant(merchantId);
  }

  return (
    <nav className="w-full selection:bg-white border-gray-200 px-4 py-4">
      <div className="flex flex-wrap justify-between items-center mx-auto">
        <div className="flex items-center">
          <a href="/">
            <span className="self-center text-xl font-semibold whitespace-nowrap ">
              Annapurna
            </span>
          </a>
        </div>

        <div className="block w-auto">
          <div className="flex flex-row space-x-3">
            {
              showMarchantSelector
              && <MerchantSelector
                merchants={merchants}
                selectedMerchant={selectedMerchant}
                onChange={merchantSelectHandler}
                disabled={disbaleMerchantSelector}
              />
            }
            <div className="inline-flex overflow-hidden relative justify-center items-center w-10 h-10 bg-gray-200 rounded-md ">
              <span className="font-medium text-gray-600 ">{getUserInitials(user.name)}</span>
            </div>

            <div className="leading-none text-left text-gray-500">
              <p className="">{user.name.trim() ? user.name : "Merchant Admin"}</p>
              <p className="text-sm">{user.email}</p>
            </div>
            <div
              onClick={handleSignout}
              className="flex justify-center items-center py-1 px-2 cursor-pointer"
              title="Sign Out"
            >
              <FiLogOut size="25" color="red" onClick={handleSignout} />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
