import React from "react";


const MerchantSelector = ({ merchants, selectedMerchant, onChange, disabled = false }) => {

    return (
        <div>
            <select
                onChange={(e) => onChange(e.target.value)}
                disabled={merchants.length === 1 || disabled}
                value={selectedMerchant ? selectedMerchant : ""}
                className="bg-white-50 border border-gray-200 text-gray-700 text-sm rounded-md block w-full py-2"
            >
                <option value="" disabled>Select Outlet</option>
                {
                    merchants.map(merchant => {
                        return (
                            <option
                                value={merchant.id}
                                key={merchant.id}>
                                {merchant.name}
                            </option>
                        )
                    })
                }
            </select>
        </div>
    )
}
export default MerchantSelector;