import React from "react";

const VegNonVegIcon = ({isVeg }) => {
    const color = isVeg ? 'bg-green-500' : 'bg-red-500';

    return (
        <div className={`flex mr-2 border border-gray-400 p-1`}>
            <span className={`w-2 h-2 rounded-full ${color}`}></span>
        </div>
    )
}

export default VegNonVegIcon;