import React, { useState, useContext } from "react";
import { Common } from "../../../Components";
import { Order } from "../../../Components/Admin";
import * as Context from "../../../Contexts";
import * as WSConstants from "../../../Constants/websocket";

const Orders = ({ eventType, statuses = [], pageTitle, orders}) => {
  const [isLoading, setIsLoading] = useState(true);

  const {
    selectedMerchant,
    WSResponseHandler,
    selectedOrderSummaryId,
  } = useContext(Context.MerchantContext);

  const wsUrl = `${process.env.REACT_APP_WEBSOCKET_URL}/merchant/${selectedMerchant}/orders/`;
  const onLoadMessage = {
    "type": eventType,
    "message": {
      "status__in": statuses
    }
  }

  const onResponse = (data) => {
    setIsLoading(false);
    WSResponseHandler(data);
  }

  const selectedOrder = orders.filter(order => {
    const orderSummaryId = `${order.qr_id}-${order.order_by_id}`;
    return selectedOrderSummaryId === orderSummaryId;
  })[0];

  const Skeleton = () => {
    return (
      <div className="flex flex-col justify-center items-center h-[calc(100vh-72px)]">
        <Common.Spinner size={12} />
        <p className="text-base py-3">Loading please wait...</p>
      </div>
    )
  }

  return (
    <>
      {
        selectedMerchant ?
          <div>
            <div className="flex flex-col" >
              <Common.SubHeader pageTitle={pageTitle}></Common.SubHeader>

              <Common.Websocket
                url={wsUrl}
                onMessage={(e) => onResponse(e)}
                isLoading={(e) => setIsLoading(e)}
                onLoadMessage={onLoadMessage}
              >
                {
                  !isLoading ?
                    orders.length > 0
                      ? (
                        <div className="flex flex-row h-[calc(100vh-136px)]">
                          <div className="w-[38%] overflow-y-scroll border-r border-gray-200">
                            <Order.OrderSummaryList orders={orders} />
                          </div> <div className="w-[62%] overflow-y-scroll">
                            <Order.OrderDetailSection order={selectedOrder} />
                          </div>
                        </div>

                      ) : <Order.NoOrders />
                    : <Skeleton />
                }
              </Common.Websocket>

            </div >
          </div >
          : <Skeleton />
      }
    </>
  );
};

export default Orders;
