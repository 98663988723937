import * as _ from "lodash";

/**
 * Convert date to human readable format
 * @param {date object} inputDate
 * @returns {string} local date time
 */
export function dateFormatter(inputDate, options) {
  let date = new Date(inputDate);
  if (!options) {
    options = {
      hour: 'numeric',
      minute: 'numeric',
      month: 'short',
      day: 'numeric',
      hour12: true,
    };
  }
  return date.toLocaleString('en-UK', options);
}

export const getOrderItemsList = (orders) => {
  /**
   * Combine and return order items from given orders
   * @param {Array} orders
   * @returns {Array} orderItems (id, name, quantity, total_price)
   */
  let orderItems = {};
  orders.forEach(order => {
    order.items.forEach(orderItem => {
      let menuItem = orderItem.menu_item;
      let customization = orderItem.customizations ? `:${orderItem.customizations}` : "";
      let bucketkey = `${menuItem.id}:${customization}`

      if (!_.has(orderItems, bucketkey)) {
        orderItems[bucketkey] = {
          id: menuItem.id,
          name: menuItem.name,
          quantity: orderItem.quantity,
          total_price: orderItem.total_price,
        }
      } else {
        orderItems[bucketkey]['quantity'] += orderItem.quantity;
      }
    })
  });
  return Object.values(orderItems);
}


export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}
