import React, { useContext, useEffect, useState } from "react";
import { Tab } from '@headlessui/react';
import toast from "react-hot-toast";
import { Menu } from "..";
import * as Common from "../../Common";
import * as menuService from "../../../Services/menuService";
import * as Context from "../../../Contexts";
import * as Utils from "../../../Utils";

const Customization = ({ show, itemId }) => {
    const { selectedMerchant, refresh, setRefresh } = useContext(Context.MerchantContext);
    const [customizations, setCustomizations] = useState([]);
    const [selectedCustomizations, setSelectedCustomizations] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const updateCustomizationType = (data) => {
        toast.promise(
            menuService.updateCustomizationType(
                selectedMerchant,
                itemId,
                selectedCustomizations,
                data,
            ).then(response => {
                if (response.status === 200) {
                    setRefresh(!refresh);
                } else {
                    throw ("error");
                }
            }),
            {
                loading: 'Saving...',
                success: 'Customization updated successfully!',
                error: 'Something went wrong! Please try again.',
            });
    }

    const addCustomizationType = (data) => {
        data = { ...data, 'item': itemId, 'options': [] }
        toast.promise(
            menuService.addCustomizationType(selectedMerchant, itemId, data).then(response => {
                if (response.status == 201) {
                    setRefresh(!refresh);
                } else {
                    throw ("error");
                }
            }),
            {
                loading: 'Saving...',
                success: 'Customization added successfully!',
                error: 'Something went wrong! Please try again.',
            });
    }

    const deleteCustomizationType = (customizationId) => {
        toast.promise(
            menuService.deleteCustomizationType(selectedMerchant, itemId, customizationId).then(response => {
                if (response.status == 204) {
                    setRefresh(!refresh);
                } else {
                    throw ("error");
                }
            }),
            {
                loading: 'Saving...',
                success: 'Customization deleted successfully!',
                error: 'Something went wrong! Please try again.',
            });
    }

    useEffect(() => {
        setIsLoading(true);
        menuService.getMenuItemCustomizations(selectedMerchant, itemId).then(response => {
            if (response.status == 200) {
                const _customizations = response.data.results;
                setCustomizations(_customizations);
                if (_customizations.length > 0) {
                    setSelectedCustomizations(_customizations[0].id);
                }
            } else {
                toast.error('Something went wrong! Please try later.')
            }
            setIsLoading(false);
        });
    }, [itemId, refresh])

    return (
        <div className={`w-full mt-4 ${!show ? 'hidden' : null}`}>
            <div className="flex item-center justify-between text-gray-600 bg-white p-4">
                <div className="flex items-center text-sm font-medium justify-center">
                    <span>Customizations</span>
                </div>
            </div>
            <div className="flex bg-white p-4 my-1 text-sm text-gray-500">
                {
                    isLoading
                        ? (
                            <div className="flex items-center justify-center h-20">
                                <Common.Spinner />
                            </div>
                        ) : (
                            <Tab.Group
                                onChange={index => setSelectedCustomizations(customizations[index]?.id)}
                                className="flex w-full"
                                as="customizations"
                                vertical
                            >
                                <Tab.List className="flex flex-col border-r border-gray-300">
                                    {customizations.map(type => {
                                        return (
                                            <Tab
                                                key={type.id}
                                                className={({ selected }) =>
                                                    Utils.classNames(
                                                        'p-3 px-4 w-[100px] hover:bg-gray-100 text-left',
                                                        selected
                                                            ? 'bg-gray-100'
                                                            : null
                                                    )
                                                }
                                            >{type.name}</Tab>
                                        )
                                    })}
                                    <Tab className={'w-[100px]'}>
                                        <div className="p-2">
                                            <button
                                                type="button"
                                                className="h-8 w-full text-xs text-green-600 bg-green-200 rounded-md"
                                            >
                                                + Add Type
                                            </button>
                                        </div>
                                    </Tab>
                                </Tab.List>
                                <Tab.Panels className="px-3 w-full">
                                    {customizations.map(type => <Tab.Panel key={type.id}>
                                        <div className="w-full">
                                            <Menu.AddEditTypeForm
                                                type={type}
                                                onSubmit={updateCustomizationType}
                                                onDelete={() => deleteCustomizationType(type.id)}
                                                key={`form-${type.id}`}
                                            />
                                            <Menu.TypeOptionsContainer
                                                itemId={itemId}
                                                typeId={type.id} 
                                                key={`options-${type.id}`}
                                            />
                                        </div>
                                    </Tab.Panel>
                                    )}
                                    <Tab.Panel className="w-full">
                                        <div>
                                            <p className="text-md font-semibold mb-2">Add new type</p>
                                            <Menu.AddEditTypeForm onSubmit={addCustomizationType} />
                                        </div>
                                    </Tab.Panel>
                                </Tab.Panels>
                            </Tab.Group>
                        )
                }
            </div>
        </div>
    )
}
export default Customization;