import React, { useEffect, useState } from "react";
import { googleLogin } from "../../Services/authService";
import { setUser } from "../../Services/utils";
import { useNavigate } from "react-router-dom";
import { ADMIN_HOME } from "../../Constants/routes";


const GoogleLoginButton = ({ role }) => {
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const getErrorMsg = (error) => {
        try {
            return error.errors.error[0];
        } catch (error) {
            return "Something went wrong! Please try again.";
        }
    }

    const onSuccess = (data) => {
        const access_token = data.credential;
        googleLogin(access_token, role).then((resp) => {
            if (resp.data) {
                setUser(resp.data);
                window.location.reload();
            }
            if (resp.error) {
                const errorMsg = getErrorMsg(resp.error);
                setError(errorMsg)
            }
        })
    }

    const GoogleButtonRender = () => {
        /* global google */
        google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            callback: onSuccess,
        })

        google.accounts.id.renderButton(
            document.getElementById('google-login-btn'),
            {
                type: "standard",
                theme: "filled_blue",
                size: "large",
                text: "signin_with",
                width: 300,
            }
        );
        google.accounts.id.prompt();
    }

    useEffect(() => {
        try {
            GoogleButtonRender()
        } catch (err) {
            window.onload = function () {
                GoogleButtonRender()
            }
        }
    }, [])

    return (
        <div className="flex flex-col items-center">
            <span className="text-sm text-red-500 py-2">{error}</span>
            <div id="google-login-btn"></div>
        </div>
    )
}

export default GoogleLoginButton;