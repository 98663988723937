import React from "react";
import { MdOutlineFastfood } from "react-icons/md";

const NoItemSelected = () => {
    return (
        <div
            className="flex flex-col justify-center align-middle items-center mt-40"
        >
            <MdOutlineFastfood size={100} />
            <p className="text-lg my-4">No Item selected</p>
        </div>
    )
}
export default NoItemSelected;