import React, { useContext } from "react";
import { Order } from "../../Components/Admin";
import * as Constants from "../../Constants";
import * as Context from "../../Contexts";
import * as WSConstants from "../../Constants/websocket";

const Orders = () => {
  const statuses = [
    Constants.ORDER_STATUSES.ACCEPTED,
    Constants.ORDER_STATUSES.PLACED,
  ];

  const {
    orders
  } = useContext(Context.MerchantContext);

  return (
    <Order.Orders eventType={WSConstants.MESSAGE_TYPES.ORDERS_LIST}
      statuses={statuses}
      pageTitle="In Progress Orders"
      orders={orders} />
  )
};

export default Orders;
