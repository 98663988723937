import React, { useContext } from "react";
import { Menu } from "..";

const Accordion = ({ menu, selectedMenuItem, onMenuItemSelected, onMenuItemDelete }) => {

    return (
        <div>
            {
                menu.map(category => {
                    return <Menu.CatrgoryAccordionItem
                        id={category.id}
                        title={category.name}
                        is_active={category.is_active}
                        key={category.id}
                    >
                        <table className="w-full text-sm text-left text-gray-700">
                            <tbody>
                                {
                                    category.items.map(menuItem =>
                                        <Menu.MenuItem
                                            id={menuItem.id}
                                            key={menuItem.id}
                                            name={menuItem.name}
                                            price={menuItem.price}
                                            isVeg={menuItem.is_veg}
                                            isActive={menuItem.is_active}
                                            hasCustomization={menuItem.has_customization}
                                            isSelectedMenu={selectedMenuItem === menuItem.id}
                                            onClick={onMenuItemSelected}
                                            onDelete={onMenuItemDelete}
                                        />
                                    )
                                }
                            </tbody>
                        </table>
                    </Menu.CatrgoryAccordionItem>

                })
            }
        </div>
    )
}
export default Accordion;