import React from 'react';
import { BsSearch } from "react-icons/bs";

const SearchBox = ({ searchBoxName, onInputChange }) => {

    return (
        <div className="relative">
            <div className="flex flex-row-reverse absolute inset-y-0 left-0 items-center pr-3 w-full pointer-events-none">
                <BsSearch size={25} color="grey" className="pr-2" />
            </div>
            <input type="search" onChange={onInputChange} id={searchBoxName} className="block p-2.5 pr-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Search menu items" required></input>
        </div>
    )
}

export default SearchBox;