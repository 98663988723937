export const USER_ROLE_ADMIN = 'MERCHANT_ADMIN';
export const USER_ROLE_KITCHEN = 'MERCHANT_KITCHEN';
export const USER_ROLE_CAPTAIN = 'MERCHANT_CAPTAIN';

// User roles
export const USER_ROLES = [
    {
        title: 'Merchant Admin',
        role: USER_ROLE_ADMIN,
    },
    {
        title: 'Merchant Kitchen',
        role: USER_ROLE_KITCHEN,
    },
    {
        title: 'Merchant Captain',
        role: USER_ROLE_CAPTAIN,
    }
]

// Order status badges
const COMM_STYLE_CLS = 'px-2 py-[3px] rounded-md text-xs'
export const ORDER_STATUSES = {
    PLACED: 'PLACED',
    ACCEPTED: 'ACCEPTED',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
}

export const ORDER_ITEM_STATUSES = {
    NEW: 'NEW',
    READY: 'READY',
}

export const ORDER_STATUS_STYLE = {
    [ORDER_STATUSES.PLACED]: {
        title: 'NEW',
        styleClasses: `${COMM_STYLE_CLS} text-blue-500 bg-blue-200`,
    },
    [ORDER_STATUSES.ACCEPTED]: {
        title: 'ACCEPTED',
        styleClasses: `${COMM_STYLE_CLS} text-green-500 bg-green-200`,
    },
    [ORDER_STATUSES.COMPLETED]: {
        title: 'COMPLETED',
        styleClasses: `${COMM_STYLE_CLS} text-green-500 bg-green-200`,
    },
    [ORDER_STATUSES.CANCELLED]: {
        title: 'CANCELLED',
        styleClasses: `${COMM_STYLE_CLS} text-red-500 bg-red-200`,
    },
}