import React, { useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { MdError } from "react-icons/md";
import { Spinner } from "../Common";
import { STATUS_MESSAGES, RETRY_TIME } from "../../Constants/websocket";
import * as utils from "../../Services/utils";


const Websocket = ({ url, queryParams = {}, children, onMessage, isLoading, onLoadMessage = null }) => {
  const [showConnectionMessage, setShowConnectionMessage] = useState(false);
  const user = utils.getUser();

  // Websocker query params
  const _queryParams = {
    token: user ? user.token : "",
    ...queryParams,
  }

  // Connection open handler
  const wsOpen = () => {
    setShowConnectionMessage(false);
  }

  // Connection close handler
  const wsClose = () => {
    setShowConnectionMessage(true);
  }

  // Should reconnect check handler
  const wsShouldReconnect = (e) => {
    return true
  }

  // New message handler 
  const wsOnMessage = (e) => {
    const data = JSON.parse(e.data);
    return onMessage(data);
  }

  // Websocker configuration
  const wsConfig = {
    queryParams: _queryParams,
    onOpen: () => wsOpen(),
    onClose: () => wsClose(),
    shouldReconnect: (e) => wsShouldReconnect(e),
    reconnectInterval: RETRY_TIME,
    onMessage: (e) => wsOnMessage(e),
  }
  const { sendJsonMessage, readyState } = useWebSocket(url, wsConfig)

  const connectionMessageIcon = () => {
    /**
     * Return icon for connection message according to state
     * */
    const loadingStates = [ReadyState.CLOSING, ReadyState.CONNECTING];
    const isLoadingState = loadingStates.includes(readyState);

    if (isLoadingState) {
      return <Spinner />
    }

    return <MdError size={30} color={'orange'} />
  }

  const connectionMessage = () => {
    /**
     * Connection message card to show current websocket connection
     * status
     */
    const connectionStatus = {
      [ReadyState.CONNECTING]: STATUS_MESSAGES.CONNECTING,
      [ReadyState.OPEN]: STATUS_MESSAGES.CONNECTED,
      [ReadyState.CLOSING]: STATUS_MESSAGES.CLOSING,
      [ReadyState.CLOSED]: STATUS_MESSAGES.CLOSED,
      [ReadyState.UNINSTANTIATED]: STATUS_MESSAGES.UNINSTANTIATED,
    }[readyState];

    return (
      <div className="flex items-center justify-center align-middle h-[70vh] w-full">
        <div
          className="flex p-4 bg-white min-w-60 rounded-md shadow-sm items-center justify-center align-middle"
        >
          {connectionMessageIcon()}
          <div className="text-xl font-light mx-2 text-gray-500">{connectionStatus}</div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (ReadyState.CONNECTING === readyState) {
      if (onLoadMessage) {
        sendJsonMessage(onLoadMessage)
      }
      return isLoading(true)
    }
  }, [readyState]);

  return (
    <>
      {
        showConnectionMessage && ReadyState.CONNECTING !== readyState
          ? connectionMessage()
          : children
      }
    </>
  );
};

export default Websocket;
