import React, { useState } from "react";
import { Common } from "../../../Components";
import { Order } from "../../../Components/Admin";
import { ORDER_STATUSES } from "../../../Constants";
import * as utils from "../../../Utils";
import * as orderService from "../../../Services/orderServices";


const OrderCard = ({ id, order_id, order_by, qr_label, status, created, items, total_price, note }) => {
    const [isAcceptLoading, setIsAcceptLoading] = useState(false);
    const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
    const [showAddOrderItemModal, setShowAddOrderItemModal] = useState(false);
    const kotIframeId = `order-kot-${id}`;
    const isInProgressOrder = [
        ORDER_STATUSES.ACCEPTED,
        ORDER_STATUSES.PLACED,
    ].includes(status)

    const printKOT = (kotIframeId) => {
        window.frames[kotIframeId].contentWindow.print()
    }

    const acceptOrder = async (id) => {
        setIsAcceptLoading(true);
        await orderService.updateOrderStatus(id, ORDER_STATUSES.ACCEPTED)
        setIsAcceptLoading(false);
    }

    const cancelOrder = async (shouldCancelOrder) => {
        if (shouldCancelOrder) {
            await orderService.updateOrderStatus(id, ORDER_STATUSES.CANCELLED)
        }
        setShowCancelOrderModal(false);
    }

    return (
        <div className="flex flex-col bg-white p-4 my-2 mx-4" key={id}>
            <div className="flex justify-between mb-2">
                <div className="flex text-xs justify-center align-middle items-center">
                    <p>ORDER # {order_id}</p>
                    <div className="mx-1">
                        <Order.OrderStatusBadge status={status} />
                    </div>
                </div>
                <div className="flex text-sm space-x-3 justify-center align-middle items-center">
                    <p className="text-gray-500">
                        Placed on {utils.dateFormatter(created)}
                    </p>

                    {
                        status === ORDER_STATUSES.PLACED
                            ? <button
                                type="button"
                                onClick={() => acceptOrder(id)}
                                className="h-8 px-3 text-xs text-green-500 bg-green-200 rounded-md"
                            >
                                {isAcceptLoading ? <Common.Spinner size={4} /> : 'Accept'}
                            </button>
                            : null
                    }
                    {
                        isInProgressOrder &&
                        <>
                            <button
                                type="button"
                                onClick={() => printKOT(kotIframeId)}
                                className="h-8 px-3 text-xs text-gray-500 bg-white rounded-md border border-gray-200"
                            >
                                Print KOT
                            </button>
                            <Common.KOT
                                id={id}
                                orderId={order_id}
                                created={created}
                                orderBy={order_by}
                                orderItems={items}
                                qrLabel={qr_label}
                                key={id}
                            />
                        </>
                    }
                </div>
            </div>
            <div className="flex flex-col text-xs items-right">
                {
                    items.map(orderItem =>
                        <Order.OrderItemRow
                            id={orderItem.id}
                            name={orderItem.menu_item.name}
                            quantity={orderItem.quantity}
                            total_price={orderItem.total_price}
                            has_customization={orderItem.menu_item.has_customization}
                            customizations={orderItem.customizations}
                            orderItem={orderItem}
                            orderStatus={status}
                            itemStatus={orderItem.status}
                            key={orderItem.id}
                        />
                    )
                }
            </div>
            {
                isInProgressOrder &&
                <div className="flex justify-end items-center my-2 text-sm">
                    <button
                        type="button"
                        onClick={() => setShowAddOrderItemModal(true)}
                        className="h-8 px-4 text-xs text-gray-500 bg-white rounded-md"
                    >
                        + Add Item
                    </button>
                    <Order.AddOrderItem
                        showModal={showAddOrderItemModal}
                        orderId={id}
                        cancelHandler={() => setShowAddOrderItemModal(false)}
                    />
                </div>
            }
            <div className="flex flex-col justify-between border-t border-gray-200 py-2 text-sm text-gray-700">
                <div className="flex justify-between">
                    <p>{`${items.length} Items`}</p>
                    <p>&#8377; {total_price}</p>
                </div>

                <div className="flex flex-row-reverse mt-2">
                    {
                        isInProgressOrder &&
                        <div className="flex justify-end w-1/5">
                            <span
                                onClick={() => setShowCancelOrderModal(true)}
                                className="pt-2 w-auto text-right text-xs text-red-400 rounded-md cursor-pointer"
                            >
                                Cancel Order
                            </span>
                            <Common.ConfirmPopup
                                show={showCancelOrderModal}
                                title={"Cancel Order"}
                                body={"Are you sure you want to cancel the order?"}
                                confirmHandler={cancelOrder}
                            />
                        </div>
                    }
                    {note && <div className="text-sm w-4/5">
                        <span className="font-semibold">Instructions: </span>
                        <span className="text-gray-500">{note}</span>
                    </div>}
                </div>
            </div>

        </div>
    )
}

export default OrderCard;