import React from "react";
import * as Context from "../Contexts";
import PairDevice from "./PairDevice";

const Pairing = () => {
    return (
        <Context.MerchantContextProvider>
            <PairDevice />
        </Context.MerchantContextProvider>
    )
}

export default Pairing;