import client from "./axiosConfig";

export const googleLogin = (access_token, role) => {
    const config = {
        url: "/auth/google-login/",
        method: "POST",
        data: {
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            access_token: access_token,
            role: role,
        }
    }
    const response = client(config);
    return response;
}
