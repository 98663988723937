import React, { useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import * as utils from "../../Utils";

const KOT = ({ id, orderId, orderBy, created, qrLabel, orderItems }) => {
    const kotIframeId = `order-kot-${id}`;

    const orderItemsTable = (
        <table style={{ width: "100%" }}>
            <tbody>
                <tr>
                    <th style={{ textAlign: "left", width: 20 }}>#</th>
                    <th style={{ textAlign: "left" }}>Item</th>
                    <th>Quantity</th>
                </tr>
                {
                    orderItems.map((item, idx) => {
                        return (
                            <tr key={item.id}>
                                <td style={{ textAlign: "left" }}>{idx + 1}</td>
                                <td>
                                    {item.menu_item.name}
                                    {item.customizations ? ` (${item.customizations})` : null}
                                </td>
                                <td style={{ textAlign: "center" }}>{item.quantity}</td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )

    const KOTDetails = (
        <div style={{ width: "70mm" }}>
            <div style={{ fontFamily: "monospace", fontSize: 16, breakAfter: "page" }}>
                <h3 style={{ width: "100%", textAlign: "center" }}>KOT</h3>

                <hr />
                <div style={{ textAlign: "center" }}>
                    Order #{orderId}
                </div>
                <div style={{ textAlign: "center" }}>
                    {utils.dateFormatter(created)}
                </div>
                <hr />

                <div style={{ display: "flex", flexDirection: "column" }}>
                    <span><b>Customer Name:</b> {orderBy}</span>
                    <span><b>Order for:</b> {qrLabel}</span>
                </div>

                <hr />
                {orderItemsTable}
                <hr />
                <div>
                    <span>Total Items <b>{orderItems.length}</b></span>
                </div>
            </div>
        </div>

    )

    useEffect(() => {
        const iframe = document.getElementById(kotIframeId).contentWindow.document;
        iframe.open();
        iframe.write(ReactDOMServer.renderToString(KOTDetails));
        iframe.close();
    })

    return (
        <iframe id={kotIframeId} src="about:blank" className="hidden" />
    )
}

export default KOT;