import { Common } from "..";
import { BiDish } from "react-icons/bi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import * as routes from "../../Constants/routes";

const SubHeader = () => {
    const navigationItem = (title, path, icon) => {
        const isItemActive = window.location.pathname === path;
        return (
            <li className="m-0 p-0">
                <a
                    href={path}
                    className={`flex items-center ${isItemActive ? 'bg-gray-100' : null} p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100  group`}
                >
                    {icon}
                    <span className="ml-3">{title}</span>
                </a>
            </li>
        );
    }

    return (
        <>
            <Common.SubHeader>
                <ul className="space-x-4 flex">
                    {
                        navigationItem('Ongoing Orders', routes.KICTHEN_HOME, <BiDish size={25} color="gray" />)
                    }
                    {
                        navigationItem('Completed Orders', routes.KICTHEN_COMPLETED_ORDERS, <AiOutlineCheckCircle size={25} color="gray" />)
                    }
                </ul>
            </Common.SubHeader>
        </>
    )
}
export default SubHeader;