import { v4 as uuidv4 } from 'uuid';

export const getUser = () => {
    const data = localStorage.getItem('user');
    return JSON.parse(data);
}

export const setUser = (data) => {
    data = JSON.stringify(data);
    return localStorage.setItem('user', data);
}

export const clearUser = () => {
    return localStorage.removeItem('user');
}

export const validateUser = () => {
    const user = getUser();
    return user && user.role;
}

export const getDeviceId = () =>{
    let deviceId = localStorage.getItem('dId');
    if (!deviceId) {
        deviceId = uuidv4();
        localStorage.setItem('dId', deviceId);
    }
    return deviceId;
}

export const clearLocalStorage = () => {
    localStorage.clear()
}