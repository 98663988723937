import React, { useState, useEffect, useContext, useRef } from "react";
import * as userService from "../../Services/userServices";
import * as Context from "../../Contexts";
import * as Common from "../../Components/Common";
import { User } from "../../Components/Admin";
import * as _ from "lodash";

const Users = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setMerchantUsers] = useState([]);
  const [showAddUserPopUp, setAddUserPopup] = useState(false);
  const [shouldReload, setReload] = useState(true);
  const addUserRef = useRef(null);

  const {
    selectedMerchant
  } = useContext(Context.MerchantContext);

  const reload = () => {
    setReload(!shouldReload);
  };

  const Loader = () => {
    return (
      <div className="flex flex-col justify-center items-center h-[calc(100vh-72px)]">
        <Common.Spinner size={12} />
        <p className="text-base py-3">Loading please wait...</p>
      </div>
    )
  };

  const addUser = () => {
    setAddUserPopup(false);
    reload();
  }

  useEffect(() => {
    if (selectedMerchant) {
      setIsLoading(true);
      userService.getMerchantUsers(selectedMerchant).then((response) => {
        let _users = response.data.results;
        _users = _.sortBy(_users, 'first_name')
        setMerchantUsers(_users);
        setIsLoading(false);
      })
    }
  }, [selectedMerchant, shouldReload]);


  return (
    <div className="flex flex-col">
      <Common.SubHeader pageTitle={'User & Device Management'}>
        <button
          type="button"
          onClick={() => setAddUserPopup(true)}
          className="h-8 px-3 text-xs text-gray-600 bg-white rounded-md border border-gray-200 disabled:text-gray-300"
        >
          Add User
        </button>
      </Common.SubHeader>

      <div className="p-8 h-[calc(100vh-140px)] overflow-y-scroll">
        {
          !isLoading && users.length > 0
            ? <User.UserTable data={users} reloadHandler={reload} />
            : <Loader />
        }
      </div>

      <Common.Modal
        show={showAddUserPopUp}
        cancelHandler={() => setAddUserPopup(false)}
        confirmHandler={() => addUserRef.current.addUser()}
        title={'Add User'}
      >
        <User.AddUser closePopup={() => addUser()} ref={addUserRef}></User.AddUser>
      </Common.Modal>
    </div>
  );
};

export default Users;
