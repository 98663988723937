import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Spinner } from "../Common";

const Modal = ({
    show,
    title,
    children,
    confirmHandler,
    cancelHandler,
    loading = false,
    cancelLabel = 'Cancel',
    confirmLabel = 'Confirm'
}) => {

    const handleCancel = () => {
        cancelHandler();
    };

    const handleConfirm = async () => {
        if(loading) return;
        await confirmHandler();
    };

    return (
        <Transition.Root show={show} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => null}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-full p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4"
                            enterTo="opacity-100 translate-y-0"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-4"
                        >
                            <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all">
                                <div className="bg-white">
                                    <div className="text-left p-5">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg leading-6 font-medium text-gray-900"
                                        >
                                            {title}
                                        </Dialog.Title>
                                        <div className="mt-4">
                                            {children}
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 p-5 flex justify-between">
                                    {
                                        cancelHandler &&
                                        <button
                                            type="button"
                                            className="w-20 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                                            onClick={handleCancel}
                                        >
                                            {cancelLabel}
                                        </button>
                                    }
                                    {
                                        confirmHandler &&
                                        <button
                                            type="button"
                                            onClick={handleConfirm}
                                            className="bg-red-500 text-white py-2 px-4 rounded text-sm"
                                        >
                                            {loading ? <Spinner size={4} /> : confirmLabel }
                                        </button>
                                    }
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
export default Modal;