import React, { Children } from "react";

const SubHeader = ({ pageTitle, children }) => {
    return (
        <div className="bg-white border-b border-gray-200 shadow-md z-30 h-16 p-4">
            <div className="flex justify-between">
                {pageTitle && <span className="font-semibold">{pageTitle}</span>}
                {children}
            </div>
        </div>
    )
}
export default SubHeader;