import React from "react";
import { Header } from "../Common";
import { Outlet } from "react-router-dom";
import { ProtectedRoute, Toaster } from "../Common";
import * as Context from "../../Contexts";
import { SubHeader } from "../Kitchen";

const KitchenLayout = ({ children }) => {
  return (
    <Context.MerchantContextProvider>
      <ProtectedRoute>
        <div className="flex flex-col">
          <div className="bg-primary  shadow-sm z-50 h-18">
            <Header showMarchantSelector={true} disbaleMerchantSelector={true}/>
            <Toaster />
          </div>
          <div className="flex flex-row h-[calc(100vh-72px)]">
            <main className="bg-slate-100 w-full overflow-y-scroll">
              <SubHeader />
              <Outlet />
            </main>
          </div>
        </div>
      </ProtectedRoute>
    </Context.MerchantContextProvider>
  );
};

export default KitchenLayout;
