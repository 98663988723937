import React from "react"
import { Common } from "../Components";
import { BiMobile } from "react-icons/bi"
import { useNavigate } from "react-router-dom";
import { login } from "../Assets/images";


const Login = () => {
    const navigate = useNavigate();

    return (
        <Common.PreventAuthRoute>
            <div className="flex flex-col items-center justify-center align-middle h-[70vh] w-full">
                <img src={login} width="250" alt="Merchant login" />
                <p className="py-3">Annapurna Partner Admin Dashboard</p>
                <div className="flex flex-col gap-4 items-center justify-center">
                    <Common.GoogleLoginButton role={'MERCHANT_ADMIN'} />
                    <div className="text-gray-700">-- OR --</div>
                    <div className="flex w-full">
                        <button
                            type="button"
                            onClick={() => navigate('/pair-device')}
                            className="flex items-center justify-center h-12 w-full my-1 px-8  text-gray-700 bg-secondary-200 rounded-md gap-x-2"
                        >
                            <BiMobile size={16} /><span>Pair a Device</span>
                        </button>
                    </div>
                </div>
            </div>
        </Common.PreventAuthRoute>
    )
}

export default Login;