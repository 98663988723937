import React from "react";
import { Header, SideBar } from "../Common";
import { Outlet } from "react-router-dom";
import { ProtectedRoute, Toaster } from "../Common";
import * as Context from "../../Contexts";

const AdminLayout = ({ children }) => {
  return (
    <Context.MerchantContextProvider>
      <ProtectedRoute>
        <div className="flex flex-col">
          <div className="bg-primary  shadow-sm z-50 h-18">
            <Header showMarchantSelector={true} />
            <Toaster />
          </div>
          <div className="flex flex-row h-[calc(100vh-72px)]">
            <div className="w-[256px] overflow-y-scroll border-r border-gray-200">
              <SideBar />
            </div>
            <div className="w-[calc(100%-256px)] overflow-y-scroll ">
              <main className="bg-slate-100">
                <Outlet />
              </main>
            </div>
          </div>
        </div>
      </ProtectedRoute>
    </Context.MerchantContextProvider>
  );
};

export default AdminLayout;
