import React, { useState, useContext } from "react";
import { BiTrash } from "react-icons/bi";
import * as Common from "../../Common";
import * as Context from "../../../Contexts";
import * as menuService from "../../../Services/menuService";

const MenuItem = ({ id, name, price, isVeg, hasCustomization, isActive, onClick, isSelectedMenu, onDelete }) => {
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    const { selectedMerchant, refresh, setRefresh } = useContext(Context.MerchantContext);
    const rowBgColor = isSelectedMenu ? 'bg-slate-100 shadow-inner' : 'bg-white menuItem';
    const [loading, setLoading] = useState(false)

    const onMenuItemToggleChange = () => {
        let params = {
            "is_active": !isActive
        };
        setLoading(true);
        menuService.editMenuItems(selectedMerchant, id, params).then((response) => {
            if (response.status === 200) {
                setRefresh(!refresh);
                setLoading(false);
            }
        });
    };

    const deleteSelectedMenuItem = () => {
        setLoading(true);
        menuService.deleteMenuItems(selectedMerchant, id).then((response) => {
            if (response.status === 204) {
                setShowDeleteWarning(false);
                setRefresh(!refresh);
                setLoading(false);
                onDelete(id);
            }
        })
    }


    const MenuItemActions = () => {
        return (
            <>
                <Common.Toggle
                    onToggleChange={onMenuItemToggleChange}
                    toggleName={''}
                    isLoading={loading}
                    toggleStatus={isActive}
                ></Common.Toggle>

                <button type="button" onClick={() => setShowDeleteWarning(true)}>
                    <BiTrash color="#f05252bd" size={20} />
                </button>

                <Common.Modal
                    show={showDeleteWarning}
                    loading={loading}
                    cancelHandler={() => setShowDeleteWarning(false)}
                    confirmHandler={() => deleteSelectedMenuItem()}
                    title={'Delete Item'}
                >
                    <p className="text-sm text-gray-700">
                        Are you sure? You want to delete <b>{name}</b>.
                    </p>
                </Common.Modal>
            </>
        )
    }

    return (
        <tr
            key={id}
            className={`${rowBgColor} bg-white border-b border-gray-100 hover:bg-gray-100`}
        >
            <td scope="row" className="p-3 py-2  flex items-center w-[280px] cursor-pointer" onClick={() => onClick(id)}>
                <Common.VegNonVegIcon isVeg={isVeg} />
                {name}
            </td>
            <td className="p-3 py-2 cursor-pointer" onClick={() => onClick(id)}>
                &#8377; {price} {hasCustomization ? ' +' : null}
            </td>
            <td className="px-4 py-2 flex justify-end w-[100px] menuItemActions">
                <MenuItemActions />
            </td>
        </tr>
    )
}

export default MenuItem;