import React, { useState, useContext, useEffect } from "react";
import { NoOrders, OrderCard } from "../Orders";
import { Common } from "../../../Components";
import * as Context from "../../../Contexts";
import * as Constants from "../../../Constants";

const Orders = ({ eventType, statuses, orders }) => {
  const [isLoading, setIsLoading] = useState(true);

  const {
    selectedMerchant,
    WSResponseHandler
  } = useContext(Context.MerchantContext);

  const wsUrl = `${process.env.REACT_APP_WEBSOCKET_URL}/merchant/${selectedMerchant}/kitchen/`;
  const onLoadMessage = {
    "type": eventType,
    "message": {
      "status__in": statuses,
      "order__status__in" : [Constants.ORDER_STATUSES.PLACED, Constants.ORDER_STATUSES.ACCEPTED]
    }
  }

  const onResponse = (data) => {
    setIsLoading(false);
    WSResponseHandler(data);
  }

  const OrderList = ({ orders }) => {
    return (
      <div className="columns-1 md:columns-2 lg:columns-3">
        {
          orders.map(order =>
            <div className="overflow-y-auto" key={order.id}>
              <OrderCard
                id={order.id}
                orderId={order.order_id}
                lable={order.qr_label}
                items={order.items}
                createdTime={order.min_item_created}
                orderNote={order.note}
                showTime={statuses?.includes(Constants.ORDER_ITEM_STATUSES.NEW)}
                key={order.id}
              ></OrderCard>
            </div>
          )
        }
      </div>
    );
  }

  return (
    <>
      {
        selectedMerchant && (
          <Common.Websocket
            url={wsUrl}
            onMessage={(e) => onResponse(e)}
            isLoading={(e) => setIsLoading(e)}
            onLoadMessage={onLoadMessage}
          >
            <div className="flex flex-row h-[calc(100vh-136px)]">
              <div className="w-full overflow-y-scroll">
                <div className="flex flex-col w-full">
                  {
                    !isLoading
                      ? (
                        orders.length > 0
                          ? <OrderList orders={orders} />
                          : <NoOrders />
                      )
                      : (
                        <div className="text-gray-400 flex flex-col justify-center align-middle items-center h-[calc(100vh-160px)]">
                          <Common.Spinner size={10} />
                          <p>Loading</p>
                        </div>
                      )
                  }
                </div>
              </div>
            </div>
          </Common.Websocket>
        )
      }
    </>
  )
}

export default Orders;