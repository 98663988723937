import React, { useContext, useState, useEffect } from "react";
import { Menu } from "..";
import { Tab } from '@headlessui/react';
import toast from 'react-hot-toast';
import * as Context from "../../../Contexts";
import * as Common from "../../Common";
import * as menuService from "../../../Services/menuService";
import * as Utils from "../../../Utils";

const EditMenuItem = ({ onCancel, selectedMenuItem }) => {
    const { selectedMerchant, refresh, setRefresh } = useContext(Context.MerchantContext);
    const [menuDetails, setMenuDetails] = useState(null);
    const [loading, setLoading] = useState(false);

    const uploadItemImage = (image) => {
        let formData = new FormData();
        formData.append("image", image);
        menuService.uploadMenuItemImage(selectedMerchant, selectedMenuItem, formData).then((response) => {
            if (response.status === 200) {
                setMenuDetails({ ...menuDetails, image: response.data.image });
            } else {
                toast.error('Item image failed to upload!');
            }

        });
    }

    const updateMenuItem = (menuItem) => {
        setLoading(true);
        if (menuItem.image) {
            uploadItemImage(menuItem.image)
            delete menuItem.image;
        }
        toast.promise(
            menuService.editMenuItems(selectedMerchant, selectedMenuItem, menuItem).then((response) => {
                if (response.status === 200) {
                    setRefresh(!refresh);
                } else {
                    throw ("error");
                }
                setLoading(false);
            }),
            {
                loading: 'Saving...',
                success: 'Item updated successfully!',
                error: 'Something went wrong! Please try again.',
            }
        );
    };

    const removeItemImage = () => {
        uploadItemImage("");
        toast.success('Item image removed successfully!')
    }

    useEffect(() => {
        if (selectedMerchant && selectedMenuItem) {
            menuService.getSpecificMenuItem(selectedMerchant, selectedMenuItem).then((response) => {
                if (response.status === 200) {
                    setMenuDetails(response.data);
                };
            });
        }
    }, [selectedMenuItem, refresh])

    return (
        <>
            {
                selectedMenuItem && menuDetails
                    ? (
                        <div className="flex flex-col" >
                            <div className="flex justify-between w-full bg-white p-3 text-sm shadow-md z-40">
                                <span>Edit Menu Item</span>
                            </div>
                            <div className="flex flex-col h-[calc(100vh-202px)] overflow-y-scroll pt-2">
                                <div className="p-4">
                                    <Tab.Group>
                                        <Tab.List className="flex bg-slate-200 p-1">
                                            <Tab
                                                className={({ selected }) =>
                                                    Utils.classNames(
                                                        'w-full py-2.5 text-sm font-medium leading-5',
                                                        selected
                                                            ? 'bg-white text-black'
                                                            : 'text-black/50 hover:bg-white/[0.12] hover:text-black'
                                                    )
                                                }
                                            >
                                                Basic Information
                                            </Tab>
                                            <Tab
                                                className={({ selected }) =>
                                                    Utils.classNames(
                                                        'w-full py-2.5 text-sm font-medium leading-5 ',
                                                        selected
                                                            ? 'bg-white text-black'
                                                            : 'text-black/50 hover:bg-white/[0.12] hover:text-black'
                                                    )
                                                }
                                            >
                                                Customizations
                                            </Tab>
                                        </Tab.List>
                                        <Tab.Panels className="mt-2">
                                            <Tab.Panel>
                                                <Menu.MenuDetails
                                                    onCancel={onCancel}
                                                    onSubmit={updateMenuItem}
                                                    removeItemImage={removeItemImage}
                                                    itemName={menuDetails.name}
                                                    categoryId={menuDetails.category.id}
                                                    price={menuDetails.price}
                                                    hasCustomization={menuDetails.has_customization}
                                                    isVeg={menuDetails.is_veg}
                                                    image={menuDetails.image}
                                                    description={menuDetails.description}
                                                    loading={loading}
                                                />
                                            </Tab.Panel>
                                            <Tab.Panel>
                                                {
                                                    !menuDetails.has_customization
                                                    && (
                                                        <div className="flex items-center item-center w-full h-40 text-gray-600 bg-white p-4">
                                                            <p className="text-md font-semibold text-center w-full">
                                                                Enable customizations option!
                                                            </p>
                                                        </div>
                                                    )
                                                }
                                                <Menu.Customizations
                                                    itemId={menuDetails.id}
                                                    show={menuDetails.has_customization}
                                                />

                                            </Tab.Panel>
                                        </Tab.Panels>
                                    </Tab.Group>
                                </div>
                            </div>
                        </div >
                    ) : (
                        <div className="flex flex-col w-full h-full items-center justify-center">
                            <Common.Spinner />
                            <p className=" p-2 text-gray-500">Loading Items...!</p>
                        </div>
                    )
            }
        </>
    )
}
export default EditMenuItem;