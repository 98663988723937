import React, { useEffect, useState } from "react";
import { MESSAGE_TYPES } from "../Constants/websocket";
import * as merchantService from "../Services/merchantService";
import { setUser } from "../Services/utils";
export const MerchantContext = React.createContext({});

const MerchantContextProvider = (props) => {
    const [merchants, setMerchants] = useState([]);
    const [selectedMerchant, setSelectedMerchant] = useState(null);
    const [selectedOrderSummaryId, setSelectedOrderSummaryId] = useState(null);
    const [orders, setOrders] = useState([]);
    const [completed_orders, setCompletedOrders] = useState([]);
    const [menuItems, setMenuItems] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [pairingCode, setPairingCode] = useState(null);

    const WSResponseHandler = (data) => {
        switch (data.type) {
            case MESSAGE_TYPES.ORDERS_LIST:
                const _orders = data.message;
                setOrders(_orders);
                if (_orders.length > 0 && !selectedOrderSummaryId) {
                    const firstOrder = _orders[0];
                    const orderSummaryId = `${firstOrder.qr_id}-${firstOrder.order_by_id}`;
                    setSelectedOrderSummaryId(orderSummaryId)
                }
                break;

            case MESSAGE_TYPES.COMPLETED_ORDERS_LIST:
                const _completed_orders = data.message;
                setCompletedOrders(_completed_orders);
                if (_completed_orders.length > 0 && !selectedOrderSummaryId) {
                    const firstOrder = _completed_orders[0];
                    const orderSummaryId = `${firstOrder.qr_id}-${firstOrder.order_by_id}`;
                    setSelectedOrderSummaryId(orderSummaryId)
                }
                break;

            case MESSAGE_TYPES.GET_PAIRING_CODE:
                setPairingCode(data.message)
                break;

            case MESSAGE_TYPES.PAIRING_AUTH_SUCCESS:
                setUser(data.message.user)
                const _merchant_id = data.message.merchant.id
                setSelectedMerchant(_merchant_id)
                merchantService.setSelectedMerchant(_merchant_id)
                window.location.reload();
                break;

            default:
                break;
        }
    }

    const values = {
        orders,
        setOrders,
        completed_orders,
        setCompletedOrders,
        merchants,
        menuItems,
        setMenuItems,
        setMerchants,
        selectedMerchant,
        WSResponseHandler,
        setSelectedMerchant,
        selectedOrderSummaryId,
        setSelectedOrderSummaryId,
        refresh,
        setRefresh,
        pairingCode,
        setPairingCode,
    }

    useEffect(() => {
        merchantService.getMyMerchants().then(response => {
            if (response.data) {
                const _merchants = response.data.results;
                const currentSelectedMerchant = merchantService.getSelectedMerchant();
                setMerchants(_merchants);

                if (currentSelectedMerchant) {
                    setSelectedMerchant(currentSelectedMerchant)
                } else {
                    const firstMerchant = _merchants[0].id;
                    setSelectedMerchant(firstMerchant);
                    merchantService.setSelectedMerchant(firstMerchant);
                }
            }
        })
    }, [])

    return (
        <MerchantContext.Provider value={values}>
            {props.children}
        </MerchantContext.Provider>
    )
}
export default MerchantContextProvider;