import React, { useState, useContext, useCallback } from "react";
import _debounce from 'lodash/debounce';
import { MdOutlineFastfood } from "react-icons/md";
import { Common } from "../../../Components";
import { SelectedMenuItem } from ".";
import { useRef } from 'react';
import * as menuService from "../../../Services/menuService";
import * as Context from "../../../Contexts";

const AddOrderItem = ({ showModal, orderId, cancelHandler }) => {
    const [menuItems, setMenuItems] = useState([]);
    const [selectedMenuItem, setSelectedMenuItem] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { selectedMerchant } = useContext(Context.MerchantContext);
    const selectedMenuItemRef = useRef(null);

    const searchMenu = (event) => {
        setIsLoading(true);
        setSelectedMenuItem(null);
        setMenuItems([]);
        let value = event.target.value;
        if (value.length > 0) {
            let params = { search: value };
            menuService.getMenuItems(selectedMerchant, params).then((response) => {
                if (response.status === 200) {
                    setMenuItems(response.data.results);
                }
            }).finally(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }
    };
    const debounceSearchMenu = useCallback(_debounce(searchMenu, 300), []);

    const closePopup = () => {
        setMenuItems([]);
        cancelHandler(false);
    };

    const addOrdeItem = async () => {
        const currentRef = selectedMenuItemRef.current;
        if (currentRef) {
            await currentRef.orderMenuItem()
        }
        return null;
    }

    return (
        <Common.Modal
            show={showModal}
            title="Add Order Item"
            confirmLabel="Add Item"
            cancelHandler={() => closePopup()}
            confirmHandler={() => addOrdeItem()}
        >
            <div className="flex flex-col w-96">
                <div>
                    <Common.SearchBox
                        searchBoxName="search-item"
                        onInputChange={(el) => debounceSearchMenu(el)}
                    />
                </div>
                <div className="flex flex-col mt-3 h-72 overflow-auto">
                    {
                        !selectedMenuItem && !isLoading && menuItems.length > 0
                            ? menuItems.map(item => {
                                const bgColor = selectedMenuItem === item ? 'bg-slate-100' : 'hover:bg-slate-100 bg-zinc-50';
                                return <div
                                    key={item.id}
                                    onClick={() => setSelectedMenuItem(item)}
                                    className={`flex flex-col rounded-md p-2 my-0.5 border border-slate-200/50  ${bgColor} cursor-pointer`}
                                >
                                    <div className="flex flex-row justify-between text-sm">
                                        <div className="text-gray-700">{item.name}</div>
                                        <div className="text-gray-600">&#8377; {item.price}</div>
                                    </div>
                                    <div className="text-xs text-gray-500 w-[90%] truncate ..."> {item.description}</div>
                                </div>
                            }) : null
                    }
                    {
                        !isLoading && menuItems.length === 0 && (
                            <div className="flex flex-col justify-center items-center mt-14 text-gray-400">
                                <MdOutlineFastfood size={60} />
                                <span className="py-3 text-base">No menu items!</span>
                            </div>
                        )
                    }
                    {
                        isLoading && !selectedMenuItem &&
                        <div className="flex flex-col justify-center items-center mt-14 text-gray-400">
                            <Common.Spinner size={10} />
                        </div>
                    }
                    {
                        selectedMenuItem && menuItems.length > 0 &&
                        <SelectedMenuItem
                            selectedItem={selectedMenuItem}
                            orderId={orderId}
                            onOrder={() => closePopup()}
                            ref={selectedMenuItemRef}
                        />
                    }
                </div>
            </div>
        </Common.Modal>
    )
}
export default AddOrderItem;