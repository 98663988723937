import React, { useContext, useState, useEffect } from "react";
import { Menu } from "..";
import toast from 'react-hot-toast';
import * as Context from "../../../Contexts";
import * as menuService from "../../../Services/menuService";


const AddMenuItem = ({ onCancel }) => {
    const {
        selectedMerchant,
        refresh,
        setRefresh,
    } = useContext(Context.MerchantContext);
    const [loading, setLoading] = useState(false);

    const [menuDetails, setMenuDetails] = useState({});

    const addMenuItem = (menuItem) => {
        menuItem.merchant = selectedMerchant;
        var _requestMenuItem = { ...menuItem };
        delete _requestMenuItem.image;
        setLoading(true);

        toast.promise(
            menuService.addMenuItems(selectedMerchant, _requestMenuItem).then((response) => {
                if (response.status === 201) {
                    //TODO : menuDetails is set for the referesh purpose
                    setMenuDetails(menuItem);
                    setLoading(false);
                    const menuItemId = response.data.id;
                    if (menuItem.image) {
                        var formData = new FormData();
                        formData.append('image', menuItem.image);
                        menuService.uploadMenuItemImage(selectedMerchant, menuItemId, formData).then((response) => {
                            if (response.status === 200) {
                                setRefresh(!refresh);
                                toast.success('Item image added successfully!');
                            } else {
                                toast.error("Imaged failed to upload!")
                            }
                        });
                    } else {
                        setRefresh(!refresh);
                    }
                } else {
                    setLoading(false);
                    throw ("error");
                    
                };
            }),
            {
                loading: 'Saving...',
                success: 'Item added successfully!',
                error: 'Something went wrong! Please try again.',
            }
        )
    };

    useEffect(() => {
        setMenuDetails({});
    }, [refresh])

    return (
        <>
            <div className="flex flex-col" >
                <div className="flex justify-between w-full bg-white p-3 text-sm shadow-md z-40">
                    <span>Add Menu Item</span>
                </div>
                <div className="flex flex-col h-[calc(100vh-202px)] overflow-y-scroll pt-2">
                    <div className="p-4">
                        <Menu.MenuDetails
                            onSubmit={addMenuItem}
                            onCancel={onCancel}
                            loading={loading}
                            itemName={menuDetails.name}
                        />
                    </div>
                </div>
            </div >
        </>
    )
}
export default AddMenuItem;